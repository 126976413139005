import { customer } from "@/core/objects";

export const MODULE_NAME = 'shopify_customer';

export const STATE_LOADING = 'loading';
export const STATE_CUSTOMER = 'customer';
export const STATE_CREDITS = 'credits';
export const STATE_DISCOUNT_CODE = 'discountCode';
export const STATE_DISCOUNT_VALUE = 'discountValue';
export const GETTER_LOADING = 'loading';
export const GETTER_CUSTOMER = 'customer';
export const GETTER_DISCOUNT_CODE = 'discountCode';
export const GETTER_DISCOUNT_VALUE = 'discountValue';
export const GETTER_IS_ACTIVE_SUBSCRIBER = 'is_active_subscriber';
export const GETTER_IS_ELIGIBLE_FOR_MEMBERSHIP = 'is_eligible_for_membership';
export const GETTER_IS_VIP_SUBSCRIBER = 'is_vip_subscriber';
export const GETTER_CUSTOMER_HAS_FREE_SHIPPING = 'customer_has_free_shipping';
export const GETTER_IS_ADMIN = 'is_admin';
export const GETTER_ECO_FRIENDLY_PACKAGING = 'is_eco_friendly_packaging';
export const GETTER_IS_CUSTOMER_MEMBER_X = 'is_customer_member_x';
export const GETTER_MEMBER_PRICE_AVAILABLE = 'GETTER_MEMBER_PRICE_AVAILABLE';
export const MUTATION_UPDATE_CUSTOMER = 'updateCustomer';
export const MUTATION_UPDATE_CUSTOMER_ECO_FRIENDLY_PACKAGING = 'updateCustomerEcoFriendlyPackaging';

export const MUTATION_FETCH_CREDITS = 'fetchCredits';
export const MUTATION_UPDATE_DISCOUNT_CODE = 'updateDiscountCode';
export const MUTATION_UPDATE_DISCOUNT_VALUE = 'updateDiscountValue';
export const MUTATION_UPDATE_WITH_MEMBERSHIP_SELECTED = 'MUTATION_UPDATE_WITH_MEMBERSHIP_SELECTED';

export const ACTION_UPDATE_CUSTOMER_ECO_FRIENDLY_PACKAGING = 'updateCustomerEcoFriendlyPackaging';
export const ACTION_UPDATE_CUSTOMER = 'updateCustomer';
export const ACTION_FETCH_CREDITS = 'fetchCredits';
export const ACTION_UPDATE_DISCOUNT_CODE = 'updateDiscountCode';
export const ACTION_UPDATE_DISCOUNT_VALUE = 'updateDiscountValue';
export const ACTION_UPDATE_WITH_MEMBER_SELECTED = 'ACTION_UPDATE_WITH_MEMBER_SELECTED';

export const CUSTOMER_ACTIVE_SUBSCRIBER_TAGS = ['Inveterate Tier: Membership X', 'Inveterate Tier: X Pass Monthly'];

export const CUSTOMER_CANCELLED_SUBSCRIBER_TAGS = ['inveterate-cancelled'];

export const CUSTOMER_FREE_SHIPPING_TAGS = [
    'Inveterate Tier: Membership X',
    'Inveterate Tier: X Pass Monthly',
    'Active Subscriber',
    'active subscriber',
    'active_subscriber',
];

export const CUSTOMER_VIP_SUBSCRIBER_TAGS = [
    'vip_active_subscriber',
    'vipprepaid',
];

export const CUSTOMER_MEMBER_X_TAGS = [
    "Inveterate Tier: Membership X",
    'Inveterate Tier: X Pass Monthly',
];

export const CUSTOMER_ADMIN_TAGS = ['admin'];
export const CUSTOMER_ECO_FRIENDLY_PACKAGING_TAG = 'eco-friendly-packaging';

export const DEFAULT_STATE = {
    [STATE_LOADING]: true,
    [STATE_CUSTOMER]: window.__st ? customer() : null,
    [STATE_CREDITS]: 0,
    [STATE_DISCOUNT_CODE]: null,
    [STATE_DISCOUNT_VALUE]: null,
};
