export const MODULE_NAME = 'route-protection';

export const STATE_ROUTE_PROTECTION_PRODUCT = 'STATE_ROUTE_PROTECTION_PRODUCT';

export const GETTER_ROUTE_PROTECTION_PRODUCT = 'GETTER_ROUTE_PROTECTION_PRODUCT';
export const GETTER_ROUTE_PROTECTION_ITEMS = 'GETTER_ROUTE_PROTECTION_ITEMS';
export const GETTER_ROUTE_PROTECTION_ITEM_KEYS = 'GETTER_ROUTE_PROTECTION_ITEM_KEYS';
export const GETTER_REMOVE_ROUTE_PRODUCTS = 'GETTER_REMOVE_ROUTE_PRODUCTS';

export const MUTATION_UPDATE_ROUTE_PROTECTION_PRODUCT =
    'MUTATION_UPDATE_ROUTE_PROTECTION_PRODUCT';

export const ACTION_GET_ROUTE_PROTECTION_PRODUCT = 'ACTION_GET_ROUTE_PROTECTION_PRODUCT';
export const ACTION_UPDATE_ROUTE_PROTECTION_PRODUCT = 'ACTION_UPDATE_ROUTE_PROTECTION_PRODUCT';
export const ACTION_CHECK_ROUTE_PROTECTION_IN_CART = 'ACTION_CHECK_ROUTE_PROTECTION_IN_CART';
export const ACTION_REMOVE_ROUTE_PROTECTION = 'ACTION_REMOVE_ROUTE_PROTECTION';

export const DEFAULT_STATE = {
    [STATE_ROUTE_PROTECTION_PRODUCT]: null
};
