import { updateCustomerEcoFriendlyPackaging } from '@/api/client';

import Bugsnag, { PREFIX } from '@/helpers/bugsnag';
import { getCredits } from '@/api/inveterate';
import {
    MODULE_NAME as LUXE_PRODUCT_MODULE,
    GETTER_LUX_PRODUCT_ADDED
} from "@/store/minicart/lux_product/constants";

import {
    STATE_LOADING,
    STATE_CUSTOMER,
    STATE_CREDITS,
    STATE_DISCOUNT_CODE,
    STATE_DISCOUNT_VALUE,
    GETTER_LOADING,
    GETTER_CUSTOMER,
    GETTER_DISCOUNT_CODE,
    GETTER_DISCOUNT_VALUE,
    GETTER_IS_ACTIVE_SUBSCRIBER,
    GETTER_IS_ELIGIBLE_FOR_MEMBERSHIP,
    GETTER_IS_VIP_SUBSCRIBER,
    GETTER_CUSTOMER_HAS_FREE_SHIPPING,
    GETTER_IS_ADMIN,
    GETTER_ECO_FRIENDLY_PACKAGING,
    GETTER_IS_CUSTOMER_MEMBER_X,
    GETTER_MEMBER_PRICE_AVAILABLE,
    MUTATION_UPDATE_CUSTOMER,
    MUTATION_UPDATE_CUSTOMER_ECO_FRIENDLY_PACKAGING,
    MUTATION_FETCH_CREDITS,
    MUTATION_UPDATE_DISCOUNT_CODE,
    MUTATION_UPDATE_DISCOUNT_VALUE,
    ACTION_UPDATE_CUSTOMER_ECO_FRIENDLY_PACKAGING,
    ACTION_UPDATE_CUSTOMER,
    ACTION_FETCH_CREDITS,
    ACTION_UPDATE_DISCOUNT_CODE,
    ACTION_UPDATE_DISCOUNT_VALUE,
    CUSTOMER_ACTIVE_SUBSCRIBER_TAGS,
    CUSTOMER_CANCELLED_SUBSCRIBER_TAGS,
    CUSTOMER_FREE_SHIPPING_TAGS,
    CUSTOMER_VIP_SUBSCRIBER_TAGS,
    CUSTOMER_MEMBER_X_TAGS,
    CUSTOMER_ADMIN_TAGS,
    CUSTOMER_ECO_FRIENDLY_PACKAGING_TAG,
    DEFAULT_STATE,
} from "@/store/minicart/shopify/customer/constants";

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE,
        };
    },
    getters: {
        [GETTER_LOADING](state) {
            return state[STATE_LOADING];
        },
        [GETTER_CUSTOMER](state) {
            return state[STATE_CUSTOMER];
        },
        [GETTER_DISCOUNT_CODE](state) {
            return state[STATE_DISCOUNT_CODE];
        },
        [GETTER_DISCOUNT_VALUE](state) {
            return state[STATE_DISCOUNT_VALUE];
        },
        [GETTER_IS_ACTIVE_SUBSCRIBER](state, getters) {
            const customer = getters[GETTER_CUSTOMER];
            if (!customer) {
                return false;
            }

            const customer_tags = (customer?.tags || [])?.map((tag) => {
                return tag?.toLowerCase();
            });

            if (!customer_tags?.length) {
                return false;
            }

            const cancelled_subscriber_tags = CUSTOMER_CANCELLED_SUBSCRIBER_TAGS?.map((tag) => {
                return tag?.toLowerCase();
            });

            if (customer_tags?.map((tag) => {
                return cancelled_subscriber_tags?.includes(tag);
            })?.includes(true)) {
                return false;
            }

            const active_subscriber_tags = CUSTOMER_ACTIVE_SUBSCRIBER_TAGS?.map((tag) => {
                return tag?.toLowerCase();
            });

            if (!active_subscriber_tags) {
                return false;
            }

            if (!active_subscriber_tags?.length) {
                return false;
            }

            return !!customer_tags?.map((tag) => {
                return active_subscriber_tags?.includes(tag);
            })?.includes(true);
        },
        [GETTER_IS_ELIGIBLE_FOR_MEMBERSHIP](state, getters) {
            const customer = getters[GETTER_CUSTOMER];
            if (!customer) {
                return false;
            }

            const customer_tags = customer?.tags?.map((tag) => {
                return tag?.toLowerCase();
            });

            if (!customer_tags) {
                return true;
            }

            if (!customer_tags?.length) {
                return true;
            }

            const cancelled_subscriber_tags = CUSTOMER_CANCELLED_SUBSCRIBER_TAGS?.map((tag) => {
                return tag?.toLowerCase();
            });

            if (customer_tags?.map((tag) => {
                return cancelled_subscriber_tags?.includes(tag);
            })?.includes(true)) {
                return false;
            }

            return !customer_tags?.map((tag) => {
                return [`inveterate-subscriber`]?.includes(tag);
            })?.includes(true);
        },
        [GETTER_IS_VIP_SUBSCRIBER](state, getters) {
            const customer = getters[GETTER_CUSTOMER];
            if (!customer) {
                return false;
            }

            const customer_tags = customer?.tags?.map((tag) => {
                return tag?.toLowerCase();
            });

            if (!customer_tags) {
                return false;
            }

            if (!customer_tags?.length) {
                return false;
            }

            const vip_subscriber_tags = CUSTOMER_VIP_SUBSCRIBER_TAGS?.map((tag) => {
                return tag?.toLowerCase();
            });

            return !!customer_tags?.map((tag) => {
                return vip_subscriber_tags?.includes(tag);
            })?.includes(true);
        },
        [GETTER_CUSTOMER_HAS_FREE_SHIPPING](state, getters) {
            const customer = getters[GETTER_CUSTOMER];
            if (!customer) {
                return false;
            }

            const customer_tags = customer?.tags?.map((tag) => {
                return tag?.toLowerCase();
            });

            if (!customer_tags) {
                return false;
            }

            if (!customer_tags?.length) {
                return false;
            }

            const active_subscriber_tags = CUSTOMER_FREE_SHIPPING_TAGS.map((tag) => {
                return tag?.toLowerCase();
            });

            return Boolean(customer_tags?.map((tag) => {
                return !!active_subscriber_tags?.includes(tag);
            }).includes(true));
        },
        [GETTER_IS_ADMIN](state, getters) {
            const customer = getters[GETTER_CUSTOMER];
            if (!customer) {
                return false;
            }

            const customer_tags = customer?.tags?.map((tag) => {
                return tag?.toLowerCase();
            });

            if (!customer_tags) {
                return false;
            }

            if (!customer_tags?.length) {
                return false;
            }

            const admin_tags = CUSTOMER_ADMIN_TAGS?.map((tag) => {
                return tag?.toLowerCase();
            }) || [];

            return customer_tags?.map((tag) => {
                return admin_tags?.includes(tag);
            })?.includes(true) || false;
        },
        [GETTER_ECO_FRIENDLY_PACKAGING](state, getters) {
            const customer = getters[GETTER_CUSTOMER];
            if (!customer) {
                return false;
            }

            const customer_tags = customer?.tags?.map((tag) => {
                return tag?.toLowerCase();
            });

            if (!customer_tags) {
                return false;
            }

            if (!customer_tags?.length) {
                return false;
            }

            return !!customer_tags?.includes(CUSTOMER_ECO_FRIENDLY_PACKAGING_TAG?.toLowerCase());
        },
        [GETTER_IS_CUSTOMER_MEMBER_X](state, getters) {
            const customer = getters[GETTER_CUSTOMER];
            if (!customer) {
                return false;
            }

            const tags = customer?.tags?.map((tag) => {
                return tag?.toLowerCase();
            });

            if (!tags) {
                return false;
            }

            if (!tags?.length) {
                return false;
            }

            const downcased_tags = tags?.map((tag) => {
                return tag?.toLowerCase();
            });

            if (!downcased_tags) {
                return false;
            }

            if (!downcased_tags?.length) {
                return false;
            }

            const active_subscriber = getters[GETTER_IS_ACTIVE_SUBSCRIBER];
            if (!active_subscriber) {
                return false;
            }

            const member_x_tags = CUSTOMER_MEMBER_X_TAGS?.map((tag) => {
                return tag?.toLowerCase();
            });

            if (!member_x_tags?.length) {
                return false;
            }

            return !!downcased_tags?.map((tag) => {
                return member_x_tags?.includes(tag);
            })?.includes(true);
        },
        [GETTER_MEMBER_PRICE_AVAILABLE](state, getters, rootState, rootGetters) {
            return !![
                !!getters[GETTER_IS_CUSTOMER_MEMBER_X],
                !!rootGetters[`${LUXE_PRODUCT_MODULE}/${GETTER_LUX_PRODUCT_ADDED}`],
            ]?.includes(true);
        },
    },
    mutations: {
        [MUTATION_UPDATE_CUSTOMER](state, customer) {
            state[STATE_CUSTOMER] = customer;
        },
        [MUTATION_UPDATE_CUSTOMER_ECO_FRIENDLY_PACKAGING](state, enabled) {
            let tags = state[STATE_CUSTOMER].tags || [];
            if (!enabled) {
               tags = tags?.filter((tag) => {
                   return tag !== CUSTOMER_ECO_FRIENDLY_PACKAGING_TAG;
               }) || [];
            } else {
                tags?.push(CUSTOMER_ECO_FRIENDLY_PACKAGING_TAG);
            }

            state[STATE_CUSTOMER] = {
                ...state[STATE_CUSTOMER],
                tags
            };
        },
        [MUTATION_FETCH_CREDITS](state, credits) {
            state[STATE_CREDITS] = credits;
        },
        [MUTATION_UPDATE_DISCOUNT_CODE](state, discountCode) {
            state[STATE_DISCOUNT_CODE] = discountCode;
        },
        [MUTATION_UPDATE_DISCOUNT_VALUE](state, discountValue) {
            state[STATE_DISCOUNT_VALUE] = discountValue;
        },
    },
    actions: {
        [ACTION_UPDATE_CUSTOMER](context, { customer }) {
            context.commit(MUTATION_UPDATE_CUSTOMER, customer);
        },
        async [ACTION_UPDATE_CUSTOMER_ECO_FRIENDLY_PACKAGING](context, enabled) {
            try {
                const success = await updateCustomerEcoFriendlyPackaging(enabled);

                if (!success) {
                    return {
                        success: false
                    };
                }

                context.commit(MUTATION_UPDATE_CUSTOMER_ECO_FRIENDLY_PACKAGING, enabled);

                return {
                    success: true
                };
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error updating eco-packaging`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                        });
                    });
                } catch (error) {}
            }

            return {
                success: false,
            };
        },
        [ACTION_UPDATE_DISCOUNT_CODE](context, { discountCode }) {
            context.commit(MUTATION_UPDATE_DISCOUNT_CODE, discountCode);
        },
        [ACTION_UPDATE_DISCOUNT_VALUE](context, { discountValue }) {
            context.commit(MUTATION_UPDATE_DISCOUNT_VALUE, discountValue);
        },
        async [ACTION_FETCH_CREDITS](context, { customerId }) {
            if (!customerId) {
                return;
            }

            getCredits(customerId);
        },
    },
};
