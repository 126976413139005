export const MODULE_NAME = 'dashboard_products';

export const STATE_PRODUCTS = 'STATE_PRODUCTS';

export const GETTER_PRODUCTS                = 'GETTER_PRODUCTS';
export const GETTER_VARIANTS                = 'GETTER_VARIANTS';
export const GETTER_MIGRATION_VARIANT_IDS   = 'GETTER_MIGRATION_VARIANT_IDS';

export const MUTATION_SET_ALL_PRODUCTS = 'MUTATION_SET_ALL_PRODUCTS';

export const ACTION_INIT_ALL_PRODUCTS = 'ACTION_INIT_ALL_PRODUCTS';

export const DEFAULT_STATE = {
    [STATE_PRODUCTS]: {}
};
