export const MODULE_NAME = 'countries';

export const STATE_LOADING = 'loading';
export const STATE_COUNTRIES = 'countries';

export const MUTATION_SET_LOADING = 'MUTATION_SET_LOADING';
export const MUTATION_SET_COUNTRIES = 'MUTATION_SET_COUNTRIES';

export const GETTER_LOADING = 'GETTER_LOADING';
export const GETTER_COUNTRIES = 'GETTER_COUNTRIES';

export const ACTION_LOAD_COUNTRIES = 'ACTION_LOAD_COUNTRIES';
export const DEFAULT_STATE = {
    [STATE_LOADING]: false,
    [STATE_COUNTRIES]: []
};
