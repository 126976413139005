export const MODULE_NAME = 'dashboard_subscriptions';

export const STATE_LOADING = 'loading';
export const STATE_ADDRESSES = 'addresses';

export const GETTER_LOADING = 'GETTER_LOADING';
export const GETTER_ADDRESSES = 'GETTER_ADDRESSES';
export const GETTER_ACTIVE_ADDRESSES = 'GETTER_ACTIVE_ADDRESSES';
export const GETTER_ACTIVE_ADDRESSES_PAGED = 'GETTER_ACTIVE_ADDRESSES_PAGED';
export const GETTER_CANCELLED_ADDRESSES = 'GETTER_CANCELLED_ADDRESSES';
export const GETTER_CANCELLED_ADDRESSES_PAGED = 'GETTER_CANCELLED_ADDRESSES_PAGED';
export const GETTER_ADDRESSES_WITH_ITEMS = 'GETTER_ADDRESSES_WITH_ITEMS';
export const GETTER_ADDRESSES_WITHOUT_ITEMS = 'GETTER_ADDRESSES_WITHOUT_ITEMS';

export const MUTATION_UPDATE_LOADING = 'MUTATION_UPDATE_LOADING';
export const MUTATION_UPDATE_SUBSCRIPTIONS = 'MUTATION_UPDATE_SUBSCRIPTIONS';
export const MUTATION_UPDATE_SUBSCRIPTION = 'MUTATION_UPDATE_SUBSCRIPTION';

export const ACTION_INIT_SUBSCRIPTIONS = 'ACTION_INIT_SUBSCRIPTIONS';
export const ACTION_GET_SUBSCRIPTIONS = 'ACTION_GET_SUBSCRIPTIONS';
export const ACTION_UPDATE_SUBSCRIPTION = 'ACTION_UPDATE_SUBSCRIPTION';
export const ACTION_ACTIVATE_ADDRESS = 'ACTION_ACTIVATE_ADDRESS';
export const ACTION_CANCEL_ADDRESS = 'ACTION_CANCEL_ADDRESS';
export const ACTION_REORDER_ADDRESS = 'ACTION_REORDER_ADDRESS';
export const ACTION_CHANGE_NEXT_CHARGE_DATE = 'ACTION_CHANGE_NEXT_CHARGE_DATE';
export const ACTION_SAVE_ADDRESS = 'ACTION_SAVE_ADDRESS';
export const ACTION_UPDATE_ADDRESS = 'ACTION_UPDATE_ADDRESS';
export const ACTION_UPDATE_ALL_ADDRESSES = 'ACTION_UPDATE_ALL_ADDRESSES';
export const ACTION_ADD_ONETIME_TO_ADDRESS = 'ACTION_ADD_ONETIME_TO_ADDRESS';
export const ACTION_ADD_ONETIMES_TO_ADDRESS = 'ACTION_ADD_ONETIMES_TO_ADDRESS';

export const STATUS_ACTIVE = 'active';
export const STATUS_CANCELLED = 'cancelled';

export const DEFAULT_STATE = {
    [STATE_LOADING]: false,
    [STATE_ADDRESSES]: {}
};
