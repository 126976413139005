import {
    MODULE_NAME as MODULE_SHOPIFY_PRODUCTS,
    GETTER_PRODUCTS
} from '@/store/dashboard/products/constants';

import {
    STATE_LOADING,
    STATE_CDN_PRODUCTS,
    MUTATION_UPDATE_LOADING,
    MUTATION_SET_CDN_PRODUCTS,
    GETTER_LOADING,
    GETTER_CDN_PRODUCTS,
    GETTER_SELLING_PLANS,
    GETTER_CDN_VARIANTS,
    GETTER_CDN_VARIANTS_SELLING_PLANS,
    DEFAULT_STATE,
} from "@/store/dashboard/cdn_products/constants";

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_LOADING](state) {
            return state[STATE_LOADING];
        },
        [GETTER_CDN_PRODUCTS](state) {
            return state[STATE_CDN_PRODUCTS];
        },
        [GETTER_SELLING_PLANS](state, getters, rootState, rootGetters) {
            const products = Object.values(rootGetters[`${MODULE_SHOPIFY_PRODUCTS}/${GETTER_PRODUCTS}`] ||
                    {});
            if (!products) {
                return {};
            }

            if (!products?.length) {
                return {};
            }

            return products?.reduce((selling_plans, product) => {
                const id = product?.id;
                if (!id) {
                    return selling_plans;
                }

                const selling_plan_groups = product?.selling_plan_groups;
                if (!selling_plan_groups) {
                    return selling_plans;
                }

                if (!selling_plan_groups?.length) {
                    return selling_plans;
                }

                const product_selling_plans = selling_plan_groups?.reduce((product_selling_plans, product_selling_plan_group) => {
                    const selling_plans = product_selling_plan_group?.selling_plans;
                    if (!selling_plans) {
                        return product_selling_plans;
                    }

                    if (!selling_plans?.length) {
                        return product_selling_plans;
                    }

                    return [...product_selling_plans, ...selling_plans];
                }, []);

                if (!product_selling_plans) {
                    return selling_plans;
                }

                if (!product_selling_plans?.length) {
                    return selling_plans;
                }

                return {
                    ...selling_plans,
                    [id]: product_selling_plans
                };
            }, {}) || {};
        },
        [GETTER_CDN_VARIANTS](state, getters, rootState, rootGetters) {
            const products = Object.values(rootGetters[`${MODULE_SHOPIFY_PRODUCTS}/${GETTER_PRODUCTS}`] ||
                    {});
            if (!products) {
                return {};
            }

            if (!products?.length) {
                return {};
            }

            return products?.reduce((variants, product) => {
                const external_product_id = product?.id;
                if (!external_product_id) {
                    return variants;
                }

                return {
                    ...variants,
                    ...(product?.variants?.reduce((variants, variant) => {
                        const id = variant?.id;
                        if (!id) {
                            return variants;
                        }

                        return {
                            ...variants,
                            [id]: {
                                ...variant,
                                product_id: product?.id
                            }
                        };
                    }, {}) || {})
                };
            }, {}) || {};
        },
        [GETTER_CDN_VARIANTS_SELLING_PLANS](state, getters) {
            const selling_plans = getters[GETTER_SELLING_PLANS];
            if (!selling_plans) {
                return {};
            }

            const variants = getters[GETTER_CDN_VARIANTS];
            if (!variants) {
                return {};
            }

            return Object?.values(variants)?.reduce((variant_selling_plans, variant) => {
                const product_id = variant?.product_id;
                if (!product_id) {
                    return variant_selling_plans;
                }

                const id = variant?.id;
                if (!id) {
                    return variant_selling_plans;
                }

                const product_selling_plans = selling_plans[product_id];
                if (!product_selling_plans) {
                    return variant_selling_plans;
                }

                return {
                    ...variant_selling_plans,
                    [id]: product_selling_plans
                };
            }, {}) || {};
        }
    },
    mutations: {
        [MUTATION_SET_CDN_PRODUCTS](state, cdn_products) {
            state[STATE_CDN_PRODUCTS] = cdn_products;
        },
        [MUTATION_UPDATE_LOADING](state, loading) {
            state[STATE_LOADING] = loading;
        }
    }
};
