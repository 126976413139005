import {
    addVariantItem,
    getItems,
    getWishlistHash,
    removeVariantItem,
    windowVariableLoaded
} from '@/helpers/wishlist';

import {
    MODULE_NAME as MODULE_CART,
    ACTION_SUCCESS
} from "@/store/minicart/shopify/cart/constants";

import Bugsnag, { PREFIX } from '@/helpers/bugsnag';
import { getBulkProductsByIds, getProductByHandle } from "@/api/storefront";

import {
    MUTATION_UPDATE_WISHLIST,
    STATE_WISHLIST,
    GETTER_WISHLIST,
    GETTER_WISHLIST_HASH,
    GETTER_WISHLIST_ITEMS,
    GETTER_WISHLIST_HANDLES,
    ACTION_INIT_WISHLIST,
    ACTION_GET_WISHLIST,
    ACTION_TOGGLE_ITEM_IN_WISHLIST,
    ACTION_ADD_TO_WISHLIST,
    ACTION_REMOVE_FROM_WISHLIST,
    DEFAULT_STATE,
} from "@/store/dashboard/wishlist/constants";

import { EVENT_WISHLIST_INITIALIZED, EVENT_WISHLIST_UPDATED } from '@/store/events';

const identifiers = [
    {
        namespace: 'custom_fields',
        key: 'accordions'
    },
    {
        namespace: 'custom_fields',
        key: 'product_details_display'
    },
    {
        namespace: 'custom_fields',
        key: 'stats'
    },
    {
        namespace: 'custom_fields',
        key: 'best_suited_for'
    },
    {
        namespace: 'custom',
        key: 'description_title'
    },
    {
        namespace: 'custom',
        key: 'description'
    },
    {
        namespace: 'custom',
        key: 'tabs'
    },
    {
        namespace: 'custom',
        key: 'split_by_options'
    },
    {
        namespace: 'reviews',
        key: 'rating_count'
    },
    {
        namespace: 'reviews',
        key: 'rating'
    },
    {
        namespace: 'product',
        key: 'redirect_to_pdp_from_plp'
    }
];

const variant_identifiers = [
    {
        namespace: 'box',
        key: 'discount_value'
    },
    {
        namespace: 'migration',
        key: 'variant_id'
    }
];

const productFields = {
    id: true,
    availableForSale: true,
    createdAt: false,
    description: false,
    descriptionHtml: false,
    featuredImage: true,
    handle: true,
    isGiftCard: true,
    onlineStoreUrl: false,
    options: true,
    productType: false,
    publishedAt: false,
    requiresSellingPlan: true,
    sellingPlanGroups: true,
    seo: false,
    tags: true,
    title: true,
    totalInventory: true,
    updatedAt: false,
    vendor: false,
    images: false,
    media: false,
    variants: {
        availableForSale: true,
        barcode: false,
        compareAtPrice: true,
        id: true,
        image: true,
        price: true,
        quantityAvailable: true,
        requiresShipping: true,
        selectedOptions: true,
        sku: false,
        title: false,
        weight: false,
        weightUnit: false,
        metafields: true,
    },
    metafields: true,
};

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_WISHLIST](state) {
            return state[STATE_WISHLIST];
        },
        [GETTER_WISHLIST_HASH](state, getters) {
            const wishlist = getters[GETTER_WISHLIST];
            if (!wishlist) {
                return null;
            }

            const { hash } = wishlist;
            if (!hash) {
                return null;
            }

            return hash;
        },
        [GETTER_WISHLIST_ITEMS](state, getters) {
            const wishlist = getters[GETTER_WISHLIST];
            if (!wishlist) {
                return {};
            }

            const items = wishlist?.items;
            if (!items) {
                return {};
            }

            return Object.values(items || {})
                .map((product) => ({
                    ...product,
                    requires_selling_plan: [
                        Boolean(product?.requires_selling_plan || false)
                    ].includes(true)
                }))
                .reduce((products, product) => {
                    const handle = product?.handle;
                    if (!handle) {
                        return products;
                    }

                    return {
                        ...products,
                        [handle]: product
                    };
                }, {});
        },
        [GETTER_WISHLIST_HANDLES](state, getters) {
            const handles = Object.keys(getters[GETTER_WISHLIST_ITEMS] || {});
            if (!handles) {
                return [];
            }

            if (!handles?.length) {
                return [];
            }

            return handles?.map((handle) => handle?.toLowerCase());
        }
    },
    mutations: {
        [MUTATION_UPDATE_WISHLIST](state, wishlist) {
            state[STATE_WISHLIST] = wishlist;
        }
    },
    actions: {
        async [ACTION_INIT_WISHLIST](context) {
            try {
                const wishlistCustomerId = window?.localStorage?.getItem('wishlisthero_.c-id') || null;
                const __st = await windowVariableLoaded('__st');
                if (!__st) {
                    return;
                }

                const actualCustomerId = window?.__st?.cid || null;

                if (!wishlistCustomerId) {
                    try {
                        window?.localStorage?.removeItem('wishlisthero_.c-id', actualCustomerId);
                    } catch (error) {}
                }

                if (wishlistCustomerId === "undefined") {
                    try {
                        window?.localStorage?.removeItem('wishlisthero_.c-id', actualCustomerId);
                    } catch (error) {}
                }

                if (
                    wishlistCustomerId?.toString() !== actualCustomerId?.toString()
                ) {
                    try {
                        window?.localStorage?.setItem('wishlisthero_.c-id', actualCustomerId);
                    } catch (error) {}
                }

                await context.dispatch(ACTION_GET_WISHLIST);

                document.dispatchEvent(new CustomEvent(EVENT_WISHLIST_INITIALIZED, {
                    detail: {
                        handles: context.getters[GETTER_WISHLIST_HANDLES]
                    }
                }));
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error init wishlist`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                        });
                    });
                } catch (error) {}
            }
        },
        async [ACTION_GET_WISHLIST](context) {
            try {
                const hash = await getWishlistHash();
                if (!hash) {
                    return;
                }

                if (hash === "undefined") {
                    return;
                }

                const wishlistHash = window?.localStorage?.getItem('wishlisthero_.wl-h') || null;
                if (!wishlistHash) {
                    window?.localStorage?.setItem('wishlisthero_.wl-h', `${hash}`);
                }

                if (wishlistHash?.toString() !== hash?.toString()) {
                    window?.localStorage?.setItem('wishlisthero_.wl-h', `${hash}`);
                }

                const wishlistItems = await getItems();

                const ids = Array?.from(new Set((wishlistItems || [])?.map((item) => {
                    return item?.ProductId;
                })?.filter((id) => {
                    return parseInt(`${id}`);
                }))) || [];

                const items = (await getBulkProductsByIds(ids, {
                    identifiers,
                    variant_identifiers,
                }, productFields));

                context.commit(MUTATION_UPDATE_WISHLIST, {
                    hash,
                    items: (items || [])?.reduce((items, item) => {
                        const handle = item?.handle;
                        if (!handle) {
                            return items;
                        }

                        return {
                            ...items,
                            [handle]: item
                        };
                    }, {}) || {}
                });

                document.dispatchEvent(new CustomEvent(EVENT_WISHLIST_UPDATED, {
                    detail: {
                        handles: context.getters[GETTER_WISHLIST_HANDLES]
                    }
                }));
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error get wishlist`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                        });
                    });
                } catch (error) {}
            }
        },
        async [ACTION_ADD_TO_WISHLIST](context, { handle }) {
            try {
                const hash = context.getters[GETTER_WISHLIST_HASH];
                if (!hash) {
                    return;
                }

                const product = await getProductByHandle(handle, {}, productFields);
                if (!product) {
                    return;
                }

                const variants = product?.variants;
                if (!variants) {
                    return;
                }

                if (!variants?.length) {
                    return;
                }

                const variant = variants[0];
                if (!variant) {
                    return;
                }

                await addVariantItem({
                    product_id: product?.id,
                    url: product?.url,
                    variant_id: variant?.id,
                    price: variant?.price,
                    title: product?.title,
                    image: product?.featured_image?.src,
                    customer_id: window?.__st?.cid,
                    hash
                });

                await context.dispatch(ACTION_GET_WISHLIST);

                await context.dispatch(`${MODULE_CART}/${ACTION_SUCCESS}`, {
                    message: 'Item(s) added to wishlist.'
                }, {
                    root: true
                });

                document.dispatchEvent(new CustomEvent(EVENT_WISHLIST_UPDATED, {
                    detail: {
                        wishlist: context.getters[GETTER_WISHLIST_HANDLES]
                    }
                }));
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error adding to wishlist`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                        });
                    });
                } catch (error) {}
            }
        },

        async [ACTION_REMOVE_FROM_WISHLIST](context, { handle }) {
            try {
                const __st = await windowVariableLoaded('__st');
                if (!__st) {
                    return;
                }

                const hash = context.getters[GETTER_WISHLIST_HASH];
                if (!hash) {
                    return;
                }

                const product = await getProductByHandle(handle, {}, productFields);
                if (!product) {
                    return;
                }

                const items = (product?.variants || [])?.map((variant) => ({
                    product_id: product?.id,
                    url: product?.url,
                    variant_id: variant?.id,
                    price: variant?.price,
                    title: product?.title,
                    image: product?.featured_image?.src,
                    customer_id: __st?.cid,
                    hash
                }));

                if (!items) {
                    return;
                }

                if (!items?.length) {
                    return;
                }

                await Promise.allSettled((items || [])?.map((item) => {
                    return removeVariantItem(item);
                }) || []);

                await context.dispatch(ACTION_GET_WISHLIST);

                await context.dispatch(`${MODULE_CART}/${ACTION_SUCCESS}`, {
                    message: 'Item(s) removed from wishlist.'
                }, {
                    root: true
                });

                document.dispatchEvent(new CustomEvent(EVENT_WISHLIST_UPDATED, {
                    detail: {
                        wishlist: context.getters[GETTER_WISHLIST_HANDLES]
                    }
                }));
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error removing from wishlist`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                        });
                    });
                } catch (error) {}
            }
        },
        async [ACTION_TOGGLE_ITEM_IN_WISHLIST](context, { handle }) {
            try {
                const handles = context.getters[GETTER_WISHLIST_HANDLES] || [];
                if (!handles) {
                    await context.dispatch(ACTION_ADD_TO_WISHLIST, {
                        handle
                    });

                    return;
                }

                if (!handles?.length) {
                    await context.dispatch(ACTION_ADD_TO_WISHLIST, {
                        handle
                    });

                    return;
                }

                if (!handles?.includes(handle?.toLowerCase())) {
                    await context.dispatch(ACTION_ADD_TO_WISHLIST, {
                        handle
                    });

                    return;
                }

                await context.dispatch(ACTION_REMOVE_FROM_WISHLIST, {
                    handle
                });
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error toggling wishlist item`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                        });
                    });
                } catch (error) {}
            }
        }
    }
};
