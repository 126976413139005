import { listOrders } from '@/api/rechargeSDKClient';

import {
    STATE_LOADING,
    STATE_ORDERS,
    GETTER_LOADING,
    GETTER_ORDERS,
    MUTATION_UPDATE_LOADING,
    MUTATION_UPDATE_ORDERS,
    ACTION_INIT_ORDERS,
    DEFAULT_STATE,
} from "@/store/dashboard/orders/constants";
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_LOADING](state) {
            return state[STATE_LOADING];
        },
        [GETTER_ORDERS](state) {
            return state[STATE_ORDERS] || [];
        }
    },
    mutations: {
        [MUTATION_UPDATE_LOADING](state, loading) {
            state[STATE_LOADING] = loading;
        },
        [MUTATION_UPDATE_ORDERS](state, orders) {
            state[STATE_ORDERS] = orders;
        }
    },
    actions: {
        async [ACTION_INIT_ORDERS](context) {
            try {
                context.commit(MUTATION_UPDATE_LOADING, true);

                const orders = await listOrders({
                    status: 'success',
                    sort_by: 'updated_at-desc',
                    limit: 250
                })?.then((response) => {
                    return response?.orders || {};
                })?.then((orders) => {
                    return Object?.values(orders) || [];
                });

                context.commit(MUTATION_UPDATE_ORDERS, orders);
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Init orders error`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            response: error?.data || error?.response,
                            error,
                        });
                    });
                } catch (error) {}
            } finally {
                context.commit(MUTATION_UPDATE_LOADING, false);
            }
        }
    }
};
