import axios from 'axios';
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

const client = axios.create({
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
});

const unwrapResponse = (response) => {
    const { data } = response;
    if (!data) {
        throw new Error();
    }

    const { status } = data;
    if (status >= 400) {
        throw new Error(JSON.stringify(data));
    }

    return response;
};

export const getMultipassToken = async (email) => {
    try {
        const token = await client
            .post(`${
                import.meta.env.VITE_APP_URL || `/apps/dashboard-g`
            }/api/core/customer/multipass.json`,
            {
                email
            })
            .then((response) => unwrapResponse(response))
            .then((response) => response?.data?.token);

        if (!token) {
            return null;
        }

        return token;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Get multipass token error`), (event) => {
                event.severity = 'error';

                event.addMetadata('Error', {
                    url: `${
                        import.meta.env.VITE_APP_URL || `/apps/dashboard-g`
                    }/api/core/customer/multipass.json`,
                    method: "POST",
                    data: {
                        email,
                    },
                    error,
                });
            });
        } catch (error) {}

        return null;
    }
};

export const sendPaymentMethodEmail = async (processor_payment_method_token) => {
    try {
        await client
            .put(`${
                    import.meta.env.VITE_APP_URL || `/apps/dashboard-g`
                }/api/core/customer/payment_method.json`,
                {
                    processor_payment_method_token
                })
            .then((response) => unwrapResponse(response))
            .then((response) => response?.data);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to send payment method email`), (event) => {
                event.severity = 'error';

                event.addMetadata('Error', {
                    url: `${
                        import.meta.env.VITE_APP_URL || `/apps/dashboard-g`
                    }/api/core/customer/payment_method.json`,
                    method: "PUT",
                    data: {
                        processor_payment_method_token,
                    },
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const updateCustomerEcoFriendlyPackaging = async (enabled) => {
    try {
        const ok = await client
            .post(`${
                import.meta.env.VITE_APP_URL || `/apps/dashboard-g`
            }/api/shopify/customer/settings/eco-packaging.json`,
            {
                enabled
            })
            .then((response) => unwrapResponse(response))
            .then((response) => response?.data?.ok);

        if (!ok) {
            return null;
        }

        return ok;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Eco packaging error`), (event) => {
                event.severity = 'error';
                event.addMetadata('Error', {
                    url: `${
                        import.meta.env.VITE_APP_URL || `/apps/dashboard-g`
                    }/api/shopify/customer/settings/eco-packaging.json`,
                    method: "PUT",
                    data: {
                        enabled,
                    },
                    error,
                });
            });
        } catch (error) {}

        return null;
    }
};

export default {
    client,
    sendPaymentMethodEmail,
    getMultipassToken,
    updateCustomerEcoFriendlyPackaging
};
