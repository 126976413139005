import { getColors } from '@/api/storefront';

import {
    STATE_LOADING,
    STATE_COLORS,
    MUTATION_SET_LOADING,
    MUTATION_SET_COLORS,
    GETTER_LOADING,
    GETTER_COLORS,
    ACTION_LOAD_COLORS,
    DEFAULT_STATE,
} from "@/store/dashboard/colors/constants";
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_LOADING](state) {
            return state[GETTER_LOADING];
        },
        [GETTER_COLORS](state) {
            return state[STATE_COLORS];
        }
    },
    mutations: {
        [MUTATION_SET_LOADING](state, loading) {
            state[STATE_LOADING] = loading;
        },
        [MUTATION_SET_COLORS](state, colors) {
            state[STATE_COLORS] = colors;
        }
    },
    actions: {
        async [ACTION_LOAD_COLORS](context) {
            try {
                context.commit(MUTATION_SET_LOADING, false);

                const colors = await getColors();

                context.commit(MUTATION_SET_COLORS, colors);
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error fetching Storefront colors`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                        });
                    });
                } catch (error) {}
            } finally {
                context.commit(MUTATION_SET_LOADING, false);
            }
        }
    }
};
