import { EVENT_MINICART_OPEN } from '@/store/events';

export function hasInveterateProduct(cart) {
    return (cart.items || [])?.reduce((has_inveterate_product, item) => {
        // exception for xhook
        if (!!item?.properties?._inveterate_subscription) {
            return true;
        }

        const { product } = item;
        if (!product) {
            return has_inveterate_product;
        }

        const { tags } = product;
        if (!tags) {
            return has_inveterate_product;
        }

        if (!tags?.length) {
            return has_inveterate_product;
        }

        return !![
            has_inveterate_product,
            Boolean(tags?.map((tag) => {
                return tag?.toLowerCase();
            })?.includes('inveterate-product'))
        ]?.includes(true);
    }, false);
}

export function processInveterateProducts(cart, customer = null) {
    const buttons = Array.prototype.slice.call(document.querySelectorAll('[data-product-inveterate-submit]'));
    if (!buttons) {
        return;
    }

    if (!buttons.length) {
        return;
    }

    const member_tags = ['Inveterate Tier: Membership X', 'active_subscriber', 'Inveterate Tier: X Pass Monthly'];
    if (customer) {
        const customer_tags = (customer?.tags || []).map((tag) => {
            return tag?.toLowerCase();
        });

        const is_active_subscriber = !!(member_tags || [])?.map((member_tag) => customer_tags?.includes(member_tag?.toLowerCase()))?.includes(true);

        if (is_active_subscriber) {
            buttons?.forEach((button) => {
                button?.setAttribute('disabled', 'disabled');
                button?.classList?.add('disabled');
                button.disabled = true;

                const text = button?.querySelector(`[data-product-submit-text]`);
                if (!text) {
                    return;
                }

                text.innerHTML = 'You are active member';
            });

            return;
        }
    }

    const has_inveterate_product = hasInveterateProduct(cart);
    if (!!has_inveterate_product) {
        buttons?.forEach((button) => {
            button?.setAttribute('disabled', 'disabled');
            button.disabled = true;

            const text = button?.querySelector(`[data-product-submit-text]`);
            if (!text) {
                return;
            }

            text.innerHTML = 'Already in cart';
        });

        return;
    }

    buttons?.forEach((button) => {
        button?.removeAttribute('disabled');
        button.disabled = false;
        button?.classList?.remove('disabled');

        const text = button.querySelector(`[data-product-submit-text]`);
        if (!text) {
            return;
        }

        text.innerHTML = 'Add Subscription';
    });
}

export function openMinicartBySearchParams() {
    const url = new URL(window.location.href);
    if (!url) {
        return;
    }

    const { searchParams } = url;
    if (!searchParams) {
        return;
    }

    if (!searchParams) {
        return;
    }

    const params = ['cart_redirect', 'cart-updated'];

    if (!params?.map((param) => {
        return searchParams.has(param);
    })?.includes(true)) {
        return;
    }

    document.dispatchEvent(new CustomEvent(EVENT_MINICART_OPEN));

    params?.forEach((param) => {
        searchParams?.delete(param);
    });

    if (!window?.history) {
        return;
    }

    window?.history?.replaceState({}, '', `${window?.location?.pathname}`);
}

export function updateCartCounters(event) {
    const { detail } = event;
    if (!detail) {
        return;
    }

    const { item_count } = detail;
    if (!![undefined, null]?.includes(item_count)) {
        return;
    }

    const counters = Array?.prototype?.slice?.call(document?.querySelectorAll('[data-minicart-count]'));
    if (!counters) {
        return;
    }

    if (!counters?.length) {
        return;
    }

    counters?.forEach((counter) => {
        const old_value_node = counter?.querySelector('[data-minicart-count-old]');
        if (!old_value_node) {
            return;
        }

        const new_value_node = counter?.querySelector('[data-minicart-count-new]');
        if (!new_value_node) {
            return;
        }

        if (new_value_node?.innerHTML?.toString() === item_count?.toString()) {
            return;
        }

        old_value_node.innerHTML = new_value_node?.innerHTML || 0;
        new_value_node.innerHTML = item_count;

        const pingEl = counter?.closest('div')?.querySelector('[data-minicart-count-ping]');
        if (!!pingEl) {
            const newPingEl = pingEl?.cloneNode(true);

            pingEl?.parentNode?.replaceChild(newPingEl, pingEl);
        }

        const new_el_counter = counter?.cloneNode(true);
        counter?.parentNode?.replaceChild(new_el_counter, counter);
    });
}

export default {
    hasInveterateProduct,
    processInveterateProducts,
    openMinicartBySearchParams,
    updateCartCounters,
};
