import axios from 'axios';

import { routes } from '@/core/objects';
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

const client = axios.create({
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest'
    }
});

export async function getOrders(params) {
    const defaultOrders = {
        orders: [],
        paginate: {}
    };

    try {
        return await axios.get(`${routes('root_url') || ''}account`, {
            params: {
                view: 'order-history.json',
                page: 1,
                ...params
            }
        })?.then((response) => {
            return response?.data;
        });
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Get orders error`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `${routes('root_url') || ''}account`,
                    method: "GET",
                    params: {
                        view: 'order-history.json',
                        page: 1,
                        ...params
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}
    }

    return defaultOrders;
}

export async function getOrdersPagination(params) {
    const ordersPagination = {
        orders: [],
        paginate: {}
    };

    try {
        return await axios.get(`${routes('root_url') || ''}account`, {
            params: {
                view: 'order-history-pagination.json',
                page: 1,
                ...params
            }
        })?.then((response) => {
            return response?.data;
        });
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Get orders pagination error`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `${routes('root_url') || ''}account`,
                    method: "GET",
                    params: {
                        view: 'order-history-pagination.json',
                        page: 1,
                        ...params
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}
    }

    return ordersPagination;
}

export async function getOrder(hash) {
    const order = null;

    try {
        return await axios.get(`${routes('root_url') || ''}account/orders/${hash}`, {
            params: {
                view: 'json-view',
            }
        })?.then((response) => {
            return response?.data;
        });
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Get order error`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `${routes('root_url') || ''}account/orders/${hash}`,
                    method: "GET",
                    params: {
                        view: 'json-view',
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}
    }

    return order;
}

export default {
    client,
    getOrders,
    getOrdersPagination,
    getOrder,
};
