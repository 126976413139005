export const MODULE_NAME = 'dashboard_boxes';

export const STATE_LOADING = 'loading';
export const STATE_BOX = 'box';

export const ACTION_INIT_BOX = 'ACTION_INIT_BOX';

export const MUTATION_UPDATE_LOADING = 'MUTATION_UPDATE_LOADING';
export const MUTATION_SET_BOX = 'MUTATION_SET_BOX';

export const GETTER_LOADING = 'GETTER_LOADING';
export const GETTER_BOX = 'GETTER_BOX';
export const GETTER_BOX_PRODUCT_IDS = 'GETTER_BOX_PRODUCT_IDS';
export const GETTER_ALL_PRODUCTS_IN_BOXES = 'GETTER_ALL_PRODUCTS_IN_BOXES';
export const GETTER_ALL_FREQUENCIES = 'GETTER_ALL_FREQUENCIES';

export const DEFAULT_STATE = {
    [STATE_LOADING]: true,
    [STATE_BOX]: null
};
