import Vue from 'vue';

import {
    MODULE_NAME as SHOPIFY_MODULE_CART,
    GETTER_HAS_ITEMS as SHOPIFY_GETTER_HAS_ITEMS, GETTER_CART_WITH_PRODUCTS
} from '@/store/minicart/shopify/cart/constants';

import {
    MODULE_NAME as MODULE_BOXES,
    GETTER_BOX_ITEM_KEYS,
    GETTER_HAS_BOXES
} from '@/store/minicart/boxes/constants';

import {
    MODULE_NAME as MODULE_BUNDLES,
    GETTER_BUNDLE_ITEM_KEYS,
    GETTER_HAS_BUNDLES
} from '@/store/minicart/bundles/constants';

import {
    MODULE_NAME as MODULE_ROUTE_PROTECTION,
    GETTER_ROUTE_PROTECTION_ITEM_KEYS
} from '@/store/minicart/route-protection/constants';

import {
    STATE_CART_OPENED,
    MUTATION_UPDATE_CART_OPENED,
    GETTER_CART,
    GETTER_EXCLUDED_ITEMS,
    GETTER_CART_EMPTY,
    GETTER_CART_OPENED,
    ACTION_UPDATE_CART_OPENED,
    DEFAULT_STATE,
} from "@/store/minicart/cart/constants";

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_EXCLUDED_ITEMS](
            state, getters, rootState, rootGetters
        ) {
            return [
                ...rootGetters[`${MODULE_BOXES}/${GETTER_BOX_ITEM_KEYS}`],
                ...rootGetters[`${MODULE_BUNDLES}/${GETTER_BUNDLE_ITEM_KEYS}`],
                ...rootGetters[
                    `${MODULE_ROUTE_PROTECTION}/${GETTER_ROUTE_PROTECTION_ITEM_KEYS}`
                ]
            ];
        },
        [GETTER_CART](
            state, getters, rootState, rootGetters
        ) {
            const cart = rootGetters[`${SHOPIFY_MODULE_CART}/${GETTER_CART_WITH_PRODUCTS}`];
            const excluded_items = getters[GETTER_EXCLUDED_ITEMS];

            if (!excluded_items) {
                return cart;
            }

            if (!excluded_items?.length) {
                return cart;
            }

            return {
                ...cart,
                items: (cart.items || []).filter((item) => !excluded_items.includes(item.key))
            };
        },
        [GETTER_CART_WITH_PRODUCTS](state, getters) {
            return getters[GETTER_CART];
        },
        [GETTER_CART_EMPTY](state, getters, rootState, rootGetters) {
            return ![
                rootGetters[`${MODULE_BOXES}/${GETTER_HAS_BOXES}`],
                rootGetters[`${MODULE_BUNDLES}/${GETTER_HAS_BUNDLES}`],
                rootGetters[`${SHOPIFY_MODULE_CART}/${SHOPIFY_GETTER_HAS_ITEMS}`]
            ]?.includes(true);
        },
        [GETTER_CART_OPENED](state) {
            return state[STATE_CART_OPENED];
        }
    },
    mutations: {
        [MUTATION_UPDATE_CART_OPENED](state, cart_opened) {
            state[STATE_CART_OPENED] = cart_opened;
        }
    },
    actions: {
        [ACTION_UPDATE_CART_OPENED](context, cart_opened) {
            const toast = Vue.$toast;
            if (!toast) {
                context.commit(MUTATION_UPDATE_CART_OPENED, cart_opened);
                return;
            }

            context.commit(MUTATION_UPDATE_CART_OPENED, cart_opened);
        }
    }
};
