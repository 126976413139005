export const MODULE_NAME = 'shopify_global';

export const STATE_LOADING = 'loading';
export const STATE_SETTINGS = 'settings';
export const STATE_CUSTOMIZER = 'customizer';
export const STATE_SHOP = 'shop';
export const STATE_OBJECTS = 'objects';
export const STATE_TRANSLATIONS = 'translations';
export const STATE_PAGE_UNLOADED = 'page_unloaded';

export const GETTER_LOADING = 'loading';
export const GETTER_SETTINGS = 'settings';
export const GETTER_CUSTOMIZER = 'customizer';
export const GETTER_SHOP = 'shop';
export const GETTER_OBJECTS = 'objects';

export const GETTER_PAGE_UNLOADED = "page_unloaded";

export const MUTATION_UPDATE_LOADING = 'updateLoading';
export const MUTATION_UPDATE_SETTINGS = 'updateSettings';
export const MUTATION_UPDATE_CUSTOMIZER = 'updateCustomizer';
export const MUTATION_UPDATE_SHOP = 'updateShop';
export const MUTATION_UPDATE_OBJECTS = 'updateObjects';
export const MUTATION_UPDATE_PAGE_UNLOADED = 'MUTATION_UPDATE_PAGE_UNLOADED';

export const ACTION_UPDATE_LOADING = 'updateLoading';
export const ACTION_UPDATE_SETTINGS = 'updateSettings';
export const ACTION_UPDATE_CUSTOMIZER = 'updateCustomizer';
export const ACTION_UPDATE_SHOP = 'updateShop';
export const ACTION_UPDATE_OBJECTS = 'updateObjects';
export const ACTION_UPDATE_TRANSLATIONS = 'updateTranslations';
export const ACTION_UPDATE_PAGE_UNLOADED = 'ACTION_UPDATE_PAGE_UNLOADED';

export const DEFAULT_GLOBALS = {
    [STATE_LOADING]: true,
    [STATE_SETTINGS]: {},
    [STATE_CUSTOMIZER]: {},
    [STATE_SHOP]: null,
    [STATE_OBJECTS]: null,
    [STATE_TRANSLATIONS]: null,
    [STATE_PAGE_UNLOADED]: false,
};
