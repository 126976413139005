export const MODULE_NAME = 'dashboard_orders';

export const STATE_LOADING = 'loading';
export const STATE_ORDERS = 'orders';

export const GETTER_LOADING = 'GETTER_LOADING';
export const GETTER_ORDERS = 'GETTER_ORDERS';

export const MUTATION_UPDATE_LOADING = 'MUTATION_UPDATE_LOADING';
export const MUTATION_UPDATE_ORDERS = 'MUTATION_UPDATE_ORDERS';

export const ACTION_INIT_ORDERS = 'ACTION_INIT_ORDERS';

export const DEFAULT_STATE = {
    [STATE_LOADING]: false,
    [STATE_ORDERS]: []
};
