import {
    MODULE_NAME as DASHBOARD_CDN_PRODUCTS,
    GETTER_SELLING_PLANS
} from '@/store/dashboard/cdn_products/constants';

import {
    MODULE_NAME as MODULE_PRODUCTS,
    GETTER_PRODUCTS
} from '@/store/dashboard/products/constants';

import { getBox } from '@/api/storefront';

import {
    STATE_LOADING,
    STATE_BOX,
    ACTION_INIT_BOX,
    MUTATION_UPDATE_LOADING,
    MUTATION_SET_BOX,
    GETTER_LOADING,
    GETTER_BOX,
    GETTER_BOX_PRODUCT_IDS,
    GETTER_ALL_PRODUCTS_IN_BOXES,
    GETTER_ALL_FREQUENCIES,
    DEFAULT_STATE,
} from "@/store/dashboard/boxes/constants";

import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_LOADING](state) {
            return state[STATE_LOADING];
        },
        [GETTER_BOX_PRODUCT_IDS](state) {
            const box = state[STATE_BOX];
            if (!box) {
                return [];
            }

            const tabs = box?.tabs;
            if (!tabs) {
                return [];
            }

            if (!tabs?.length) {
                return [];
            }

            return tabs?.reduce((product_ids, tab) => {
                const products = tab?.products;
                if (!products) {
                    return product_ids;
                }

                if (!products?.length) {
                    return product_ids;
                }

                return [...product_ids, ...products];
            }, []) || [];
        },
        [GETTER_BOX](state, getters, rootState, rootGetters) {
            const all_products = rootGetters[`${MODULE_PRODUCTS}/${GETTER_PRODUCTS}`];
            if (!all_products) {
                return null;
            }

            const box = state[STATE_BOX];
            if (!box) {
                return null;
            }

            return {
                ...box,
                tabs: (box?.tabs || [])?.map((tab) => ({
                    ...tab,
                    products: (tab?.products || [])
                        ?.reduce((products, id) => {
                            const product = all_products[id];
                            if (!product) {
                                return products;
                            }

                            return [...products, product];
                        }, [])
                        ?.map((product) => ({
                            ...product,
                            requires_selling_plan: Boolean(product?.requires_selling_plan)
                        }))
                })) || []
            };
        },
        [GETTER_ALL_PRODUCTS_IN_BOXES](state, getters) {
            const box = getters[GETTER_BOX];
            if (!box) {
                return [];
            }

            const handle = box?.handle;
            if (!handle) {
                return [];
            }

            const tabs = box?.tabs;
            if (!tabs) {
                return [];
            }

            return (tabs || [])?.reduce((products, tab) => {
                return [
                    ...products,
                    ...Object.values(tab?.products || {})
                ];
            }, [])?.reduce((products, product) => {
                const id = product?.id;
                if (!id) {
                    return products;
                }

                return {
                    ...products,
                    [id]: product
                };
            }, []) || [];
        },
        [GETTER_ALL_FREQUENCIES](
            state, getters, rootState, rootGetters
        ) {
            const products = Object.values(getters[GETTER_ALL_PRODUCTS_IN_BOXES] || {});
            if (!products) {
                return {};
            }

            if (!products?.length) {
                return {};
            }

            const selling_plans =
                rootGetters[`${DASHBOARD_CDN_PRODUCTS}/${GETTER_SELLING_PLANS}`];
            if (!selling_plans) {
                return {};
            }

            return products?.reduce((options, product) => {
                const id = product?.id;
                if (!id) {
                    return options;
                }

                const product_selling_plans = selling_plans[id];
                if (!product_selling_plans) {
                    return options;
                }

                return {
                    ...options,
                    ...(product_selling_plans?.reduce((options, selling_plan) => {
                        const order_interval_frequency = selling_plan?.order_interval_frequency;
                        const order_interval_unit_type = selling_plan?.order_interval_unit_type;

                        return {
                            ...options,
                            [`${order_interval_frequency} ${order_interval_unit_type}${
                                order_interval_frequency > 1 ? 's' : ''
                            }`]: {
                                order_interval_frequency,
                                order_interval_unit_type
                            }
                        };
                    }, {}) || {})
                };
            }, {}) || {};
        }
    },
    mutations: {
        [MUTATION_UPDATE_LOADING](state, loading) {
            state[STATE_LOADING] = loading;
        },
        [MUTATION_SET_BOX](state, box) {
            state[STATE_BOX] = box;
        }
    },
    actions: {
        async [ACTION_INIT_BOX]({ commit }, { handle = null }) {
            if (!handle) {
                return;
            }

            commit(MUTATION_UPDATE_LOADING, true);

            try {
                const box = await getBox(handle, {}, {
                    fields: {
                        key: true,
                        value: true,
                        reference: {
                            media: {
                                id: false,
                                updatedAt: false,
                            },
                            metaobject: {
                                id: false,
                                updatedAt: false,
                            },
                        },
                        references: {
                            media: {
                                id: false,
                                updatedAt: false,
                            },
                            metaobject: {
                                id: false,
                                updatedAt: false,
                            },
                        },
                    },
                    handle: true,
                    id: false,
                    type: true,
                    updatedAt: false,
                });

                commit(MUTATION_SET_BOX, box || null);
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Get box error`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}
            } finally {
                commit(MUTATION_UPDATE_LOADING, false);
            }
        }
    }
};
