<template>
    <svg
        class="icon icon-close"
        aria-hidden="true"
    >
        <use xlink:href="#close" />
    </svg>
</template>

<script>
    export default {
        name: "CloseIcon"
    };
</script>
