export default function splitArrayIntoPages(array, pageSize) {
    const pages = [];
    const totalPages = Math.ceil(array.length / pageSize);

    for (let i = 0; i < totalPages; i++) {
        const start = i * pageSize;
        const end = start + pageSize;
        const page = array.slice(start, end);
        pages.push(page);
    }

    return pages.reduce((
        pages, page, index
    ) => ({
        ...pages,
        [index + 1]: page
    }), {});
}
