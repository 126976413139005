export default class FetchTimeoutError extends Error {
    request = null;

    constructor(message, request) {
        super(message);

        this.name = "FetchTimeoutError";
        this.request = request;
    }
};
