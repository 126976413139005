export const MODULE_NAME = 'dashboard_cdn_products';

export const STATE_LOADING = 'loading';
export const STATE_CDN_PRODUCTS = 'cdn_products';

export const MUTATION_UPDATE_LOADING = 'MUTATION_UPDATE_LOADING';
export const MUTATION_SET_CDN_PRODUCTS = 'MUTATION_SET_CDN_PRODUCTS';

export const GETTER_LOADING = 'GETTER_LOADING';
export const GETTER_CDN_PRODUCTS = 'GETTER_CDN_PRODUCTS';
export const GETTER_SELLING_PLANS = 'GETTER_SELLING_PLANS';
export const GETTER_CDN_VARIANTS = 'GETTER_CDN_VARIANTS';
export const GETTER_CDN_VARIANTS_SELLING_PLANS =
    'GETTER_CDN_VARIANTS_SELLING_PLANS';

export const DEFAULT_STATE = {
    [STATE_LOADING]: false,
    [STATE_CDN_PRODUCTS]: {}
};
