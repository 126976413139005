export default class MaxRetriesReachedError extends Error {
    exception = null;

    request     = null;

    constructor(message, exception, request) {
        super(message);

        this.name = "MaxRetriesReachedError";
        this.exception = exception;
        this.request = request;
    }
};
