export const MODULE_NAME = 'dashboard_shopify_orders';

export const STATE_LOADING = 'loading';
export const STATE_ORDERS = 'orders';
export const STATE_PAGINATION = 'pagination';
export const STATE_LAST_ORDER = 'last_order';

export const GETTER_LOADING = 'GETTER_LOADING';
export const GETTER_ORDERS = 'GETTER_ORDERS';
export const GETTER_ORDERS_PAGINATION = 'GETTER_ORDERS_PAGINATION';
export const GETTER_LAST_ORDER = 'GETTER_LAST_ORDER';

export const MUTATION_UPDATE_LOADING = 'MUTATION_UPDATE_LOADING';
export const MUTATION_UPDATE_ORDERS = 'MUTATION_UPDATE_ORDERS';
export const MUTATION_UPDATE_ORDERS_PAGINATION = 'MUTATION_UPDATE_ORDERS_PAGINATION';
export const MUTATION_UPDATE_LAST_ORDER = 'MUTATION_UPDATE_LAST_ORDER';

export const ACTION_INIT_ORDERS = 'ACTION_INIT_ORDERS';
export const ACTION_LOAD_ORDERS = 'ACTION_LOAD_ORDERS';
export const ACTION_LOAD_PAGINATION = 'ACTION_LOAD_PAGINATION';

export const DEFAULT_STATE = {
    [STATE_LOADING]: false,
    [STATE_ORDERS]: [],
    [STATE_LAST_ORDER]: null,
    [STATE_PAGINATION]: {},
};
