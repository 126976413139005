import {
    MODULE_NAME as MODULE_CART,
    GETTER_CART_WITH_PRODUCTS
} from '@/store/minicart/shopify/cart/constants';

import {
    STATE_SELECTED_BUNDLE,
    STATE_BUNDLE_CONFIGURATOR_OPENED,
    GETTER_BUNDLE_ITEMS,
    GETTER_BUNDLE_CHILD_ITEMS,
    GETTER_BUNDLE_ITEM_KEYS,
    GETTER_BUNDLE_GROUPED_ITEMS,
    GETTER_BUNDLES,
    GETTER_HAS_BUNDLES,
    GETTER_SELECTED_BUNDLE,
    GETTER_SHOW_BUNDLE_CONFIGURATOR,
    ITEM_PROPERTY_BUNDLE_KEY,
    ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT,
    MUTATION_UPDATE_SELECTED_BUNDLE,
    MUTATION_UPDATE_BUNDLE_CONFIGURATOR_OPENED,
    ACTION_UPDATE_BUNDLE_CONFIGURATOR_OPENED,
    DEFAULT_STATE,
} from "@/store/minicart/bundles/constants";

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_BUNDLE_ITEMS](store, getters, rootState, rootGetters) {
            const cart = rootGetters[`${MODULE_CART}/${GETTER_CART_WITH_PRODUCTS}`];

            if (!cart) {
                return [];
            }

            const { items } = cart;
            if (!items) {
                return [];
            }

            if (!items?.length) {
                return [];
            }

            return items?.filter((item) => {
                const { properties } = item;
                if (!properties) {
                    return false;
                }

                if (
                    !Object?.keys(properties)?.includes(ITEM_PROPERTY_BUNDLE_KEY)
                ) {
                    return false;
                }

                return !!properties[ITEM_PROPERTY_BUNDLE_KEY];
            }) || [];
        },
        [GETTER_BUNDLE_CHILD_ITEMS](store, getters) {
            const items = getters[GETTER_BUNDLE_ITEMS];
            if (!items) {
                return [];
            }

            if (!items?.length) {
                return [];
            }

            return items?.filter((item) => {
                const { properties } = item;
                if (!properties) {
                    return false;
                }

                const keys = Object?.keys(properties);
                if (!keys) {
                    return false;
                }

                if (!keys?.length) {
                    return false;
                }

                if (!keys?.includes(ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT)) {
                    return true;
                }

                const is_main_product = properties[ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT];
                if (!is_main_product) {
                    return true;
                }

                return ![
                    true,
                    'true'
                ]?.includes(is_main_product);
            }) || [];
        },
        [GETTER_BUNDLE_ITEM_KEYS](store, getters) {
            const bundle_items = getters[GETTER_BUNDLE_ITEMS];
            if (!bundle_items) {
                return [];
            }

            if (!bundle_items?.length) {
                return [];
            }

            return bundle_items?.map((bundle_item) => {
                return !!bundle_item?.key;
            }) || [];
        },
        [GETTER_BUNDLE_GROUPED_ITEMS](store, getters) {
            const items = getters[GETTER_BUNDLE_CHILD_ITEMS];
            if (!items) {
                return {};
            }

            if (!items?.length) {
                return {};
            }

            return items?.reduce((groups, item) => {
                const { properties } = item;
                if (!properties) {
                    return groups;
                }

                const bundle_product = properties[ITEM_PROPERTY_BUNDLE_KEY];
                if (!bundle_product) {
                    return groups;
                }

                return {
                    ...groups,
                    [bundle_product]: [...(groups[bundle_product] || []), item]
                };
            }, {}) || [];
        },
        [GETTER_BUNDLES](store, getters, rootState, rootGetters) {
            const items = getters[GETTER_BUNDLE_ITEMS];
            if (!items) {
                return [];
            }

            if (!items?.length) {
                return [];
            }

            const cart = rootGetters[`${MODULE_CART}/${GETTER_CART_WITH_PRODUCTS}`];
            if (!cart) {
                return [];
            }

            const cart_items = cart?.items;
            if (!items) {
                return [];
            }

            if (!cart_items?.length) {
                return [];
            }

            const groups = getters[GETTER_BUNDLE_GROUPED_ITEMS];
            if (!groups) {
                return [];
            }

            if (!Object.values(groups).length) {
                return [];
            }

            return (cart_items || [])?.filter((item) => {
                const { properties } = item;
                if (!properties) {
                    return false;
                }

                const keys = Object.keys(properties);
                if (!keys?.includes(ITEM_PROPERTY_BUNDLE_KEY)) {
                    return false;
                }

                const bundle_product = properties[ITEM_PROPERTY_BUNDLE_KEY];
                if (!bundle_product) {
                    return false;
                }

                if (!keys?.includes(ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT)) {
                    return false;
                }

                const is_main_product =
                    properties[ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT];
                if (!is_main_product) {
                    return false;
                }

                return !![true, 'true']?.includes(is_main_product);
            })?.reduce((items, item) => {
                const { properties } = item;
                if (!properties) {
                    return items;
                }

                if (!Object?.values(properties)?.length) {
                    return items;
                }

                const bundle_product = properties[ITEM_PROPERTY_BUNDLE_KEY];
                if (!bundle_product) {
                    return items;
                }

                const group = groups[bundle_product];
                if (!group) {
                    return items;
                }

                if (!group?.length) {
                    return items;
                }

                return [
                    ...items,
                    {
                        ...item,
                        sub_products: group,
                        individual_final_line_price: item.final_line_price,
                        individual_original_line_price: item.original_line_price,
                        original_price:
                            item.original_price +
                            group.reduce((original_price, sub_product) => (
                                original_price +
                                    sub_product.original_price
                            ), 0),
                        original_line_price:
                            item.original_line_price +
                            group.reduce((original_price, sub_product) => (
                                original_price +
                                    sub_product.original_line_price
                            ), 0),
                        final_price:
                            item.final_price +
                            group.reduce((final_price, sub_product) => final_price + sub_product.final_price, 0),
                        final_line_price:
                            item.final_line_price +
                            group.reduce((final_line_price, sub_product) => (
                                final_line_price +
                                        sub_product.final_line_price
                            ),
                            0)
                    }
                ];
            }, []) || [];
        },
        [GETTER_HAS_BUNDLES](state, getters) {
            const bundles = getters[GETTER_BUNDLES];
            if (!bundles) {
                return false;
            }

            return !!bundles?.length;
        },
        [GETTER_SELECTED_BUNDLE](state) {
            return state[STATE_SELECTED_BUNDLE];
        },
        [GETTER_SHOW_BUNDLE_CONFIGURATOR](state, getters) {
            return !!getters[GETTER_SELECTED_BUNDLE];
        }
    },
    mutations: {
        [MUTATION_UPDATE_SELECTED_BUNDLE](state,
            selected_bundle) {
            state[STATE_SELECTED_BUNDLE] = selected_bundle;
        },
        [MUTATION_UPDATE_BUNDLE_CONFIGURATOR_OPENED](state,
            bundle_configurator_opened) {
            state[STATE_BUNDLE_CONFIGURATOR_OPENED] = bundle_configurator_opened;
        },
    },
    actions: {
        [ACTION_UPDATE_BUNDLE_CONFIGURATOR_OPENED](context,
            { bundle_configurator_opened, bundle } = {}) {
            context.commit(MUTATION_UPDATE_SELECTED_BUNDLE, bundle);
            context.commit(MUTATION_UPDATE_BUNDLE_CONFIGURATOR_OPENED,
                bundle_configurator_opened);
        },
    }
};
