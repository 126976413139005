export const MODULE_NAME = 'lux_product';

export const STATE_LOADING = 'loading';
export const STATE_SHOW_MOBILE_LUX_PRODUCT = 'show_mobile_lux_product';
export const STATE_MOBILE_LUX_PRODUCT_CLOSED = 'mobile_lux_product_closed';

export const GETTER_LOADING = 'loading';

export const GETTER_LUX_PRODUCT = 'lux_product';
export const GETTER_LUX_PRODUCT_ADDED = 'lux_product_added';
export const GETTER_SHOW_LUX_PRODUCT_POPUP = 'show_lux_product_popup';
export const GETTER_SHOW_LUX_PRODUCT_POPUP_MOBILE =
    'show_lux_product_popup_mobile';
export const GETTER_MOBILE_LUX_PRODUCT_CLOSED = 'mobile_lux_product_closed';

export const MUTATION_UPDATE_LOADING = 'updateLoading';
export const MUTATION_SHOW_LUX_PRODUCT_POPUP_MOBILE =
    'showLuxProductPopupMobile';
export const MUTATION_UPDATE_MOBILE_LUX_PRODUCT_CLOSED =
    'updateMobileLuxProductClosed';

export const ACTION_UPDATE_SHOW_LUX_PRODUCT_POPUP_MOBILE =
    'updateShowLuxProductPopupMobile';
export const ACTION_UPDATE_MOBILE_LUX_PRODUCT_CLOSED =
    'updateMobileLuxProductClosed';
export const ACTION_REMOVE_LUX_KEY_ITEMS = 'removeLuxKeysItems';
export const ACTION_ADD_LUX_KEY_ITEM = 'addLuxKeyItem';

export const CUSTOMIZER_PROPERTY_LUX_PRODUCT = 'luxe_key_product';
export const CUSTOMIZER_ENABLE_LUX_KEY_PRODUCT_POPUP =
    'enable_luxe_key_product_popup';

export const DEFAULT_STATE = {
    [STATE_LOADING]: true,
    [STATE_MOBILE_LUX_PRODUCT_CLOSED]: false,
    [STATE_SHOW_MOBILE_LUX_PRODUCT]: false
};
