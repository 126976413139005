export const MODULE_NAME = 'cart';

export const STATE_CART_OPENED = 'cart_opened';

export const MUTATION_UPDATE_CART_OPENED = 'update_cart_opened';

export const GETTER_CART = 'cart';
export const GETTER_EXCLUDED_ITEMS = 'excluded_items';
export const GETTER_CART_EMPTY = 'cart_empty';
export const GETTER_CART_OPENED = 'cart_opened';
export const ACTION_UPDATE_CART_OPENED = 'update_cart_opened';

export const DEFAULT_STATE = {
    [STATE_CART_OPENED]: false
};
