export const MODULE_NAME = 'colors';

export const STATE_LOADING = 'loading';
export const STATE_COLORS = 'colors';

export const MUTATION_SET_LOADING = 'MUTATION_SET_LOADING';
export const MUTATION_SET_COLORS = 'MUTATION_SET_COLORS';

export const GETTER_LOADING = 'GETTER_LOADING';
export const GETTER_COLORS = 'GETTER_COUNTRIES';

export const ACTION_LOAD_COLORS = 'ACTION_LOAD_COLORS';

export const DEFAULT_STATE = {
    [STATE_LOADING]: false,
    [STATE_COLORS]: []
};
