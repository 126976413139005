export const MODULE_NAME = 'free_gift';

export const STATE_SELECTED_FREE_GIFT_PRODUCT = 'selected_free_gift_product';
export const STATE_FREE_GIFT_ADDING = 'free_gift_adding';

export const GETTER_FREE_GIFTS_ENABLED = 'free_gifts_enabled';
export const GETTER_FREE_GIFT_ADDED = 'free_gift_added';
export const GETTER_FREE_GIFT_PRODUCTS_ADDED_TO_CART =
    'free_gift_products_added_to_cart';
export const GETTER_FREE_GIFT_THRESHOLD = 'free_gift_threshold';
export const GETTER_FREE_GIFT_AMOUNT_LEFT = 'free_gift_amount_left';
export const GETTER_FREE_GIFT_PROGRESS = 'free_gift_progress';
export const GETTER_FREE_GIFT_REACHED = 'free_gift_reached';
export const GETTER_SHOW_FREE_GIFT_PROGRESS = 'show_free_gift_progress';
export const GETTER_SHOW_FREE_GIFT_SLIDER = 'show_free_gift_slider';
export const GETTER_FREE_GIFT_PRODUCTS = 'free_gift_products';
export const GETTER_SELECTED_FREE_GIFT_PRODUCT = 'selected_free_gift_product';
export const GETTER_SHOW_FREE_GIFT_PRODUCT_CONFIGURATOR =
    'show_free_gift_product_configurator';
export const GETTER_FREE_GIFT_VARIANTS = 'free_gift_variants';
export const GETTER_HAS_ONLY_ONE_GIFT_VARIANT = 'has_only_one_gift_variant';
export const GETTER_HAS_ONLY_ONE_GIFT_PRODUCT = 'has_only_one_gift_product';
export const GETTER_FREE_GIFT_ADDING = 'free_gift_adding';

export const MUTATION_UPDATE_SELECTED_FREE_GIFT_PRODUCT =
    'MUTATION_UPDATE_SELECTED_FREE_GIFT_PRODUCT';
export const MUTATION_UPDATE_FREE_GIFT_ADDING =
    'MUTATION_UPDATE_FREE_GIFT_ADDING';

export const ACTION_UPDATE_SELECTED_FREE_GIFT_PRODUCT =
    'updateSelectedFreeGiftProduct';
export const ACTION_REMOVE_UNAVAILABLE_GIFT_PRODUCTS =
    'removeUnavailableGiftProducts';
export const ACTION_REMOVE_ALL_GIFT_PRODUCTS = 'removeAllGiftProducts';
export const ACTION_ADD_DEFAULT_GIFT_PRODUCT = 'addDefaultGiftProduct';
export const ACTION_ADD_FREE_GIFT_PRODUCT = 'addFreeGiftProduct';

export const CUSTOMIZER_SETTING_FREE_GIFT_PRODUCTS_ENABLED =
    'free_gift_products_enabled';
export const CUSTOMIZER_SETTING_FREE_GIFT_THRESHOLD = 'free_gift_threshold';
export const CUSTOMIZER_SETTING_FREE_GIFT_PRODUCTS = 'free_gift_products';

export const ITEM_PROPERTY_FREE_GIFT = '_free_gift';
export const ITEM_PROPERTY_FREE_GIFT_ADDED_FROM_CART =
    '_free_gift_added_from_cart';
export const ITEM_PROPERTY_FREE_GIFT_SWAPPED = '_free_gift_swapped';

export const VARIANT_GWP_METAFIELD_NAMESPACE_NAME = 'gwp';
export const VARIANT_GWP_METAFIELD_KEY_NAME = 'enabled';

export const DEFAULT_STATE = {
    [STATE_SELECTED_FREE_GIFT_PRODUCT]: null,
    [STATE_FREE_GIFT_ADDING]: false
};
