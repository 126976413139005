import { getStyles } from '@/api/storefront';

import {
    STATE_LOADING,
    STATE_STYLES,
    MUTATION_SET_LOADING,
    MUTATION_SET_STYLES,
    GETTER_LOADING,
    GETTER_STYLES,
    ACTION_LOAD_STYLES,
    DEFAULT_STATE,
} from "@/store/dashboard/styles/constants";
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_LOADING](state) {
            return state[GETTER_LOADING];
        },
        [GETTER_STYLES](state) {
            return state[STATE_STYLES];
        }
    },
    mutations: {
        [MUTATION_SET_LOADING](state, loading) {
            state[STATE_LOADING] = loading;
        },
        [MUTATION_SET_STYLES](state, styles) {
            state[STATE_STYLES] = styles;
        }
    },
    actions: {
        async [ACTION_LOAD_STYLES](context) {
            try {
                context.commit(MUTATION_SET_LOADING, false);

                const styles = await getStyles();

                context.commit(MUTATION_SET_STYLES, styles);
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error fetching Storefront styles`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                        });
                    });
                } catch (error) {}
            } finally {
                context.commit(MUTATION_SET_LOADING, false);
            }
        }
    }
};
