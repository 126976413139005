export default function (item) {
    if (!item) {
        return null;
    }

    const { selling_plan } = item;
    if (selling_plan) {
        return selling_plan;
    }

    const { selling_plan_allocation } = item;
    if (!selling_plan_allocation) {
        return null;
    }

    const current_selling_plan = selling_plan_allocation?.selling_plan;
    if (!current_selling_plan) {
        return null;
    }

    const current_selling_plan_id = current_selling_plan?.id;
    if (!current_selling_plan_id) {
        return null;
    }

    return current_selling_plan_id;
}
