export const MODULE_NAME = 'dashboard_wishlist';

export const MUTATION_UPDATE_WISHLIST = 'MUTATION_UPDATE_WISHLIST';
export const MUTATION_UPDATE_LOADING = 'MUTATION_UPDATE_LOADING';

export const STATE_LOADING = 'loading';
export const STATE_WISHLIST = 'wishlist';

export const GETTER_LOADING = 'GETTER_LOADING';
export const GETTER_WISHLIST = 'GETTER_WISHLIST';
export const GETTER_WISHLIST_HASH = 'GETTER_WISHLIST_HASH';
export const GETTER_WISHLIST_ITEMS = 'GETTER_WISHLIST_ITEMS';
export const GETTER_WISHLIST_HANDLES = 'GETTER_WISHLIST_HANDLES';

export const ACTION_INIT_WISHLIST = 'ACTION_INIT_WISHLIST';
export const ACTION_GET_WISHLIST = 'ACTION_GET_WISHLIST';
export const ACTION_TOGGLE_ITEM_IN_WISHLIST = 'ACTION_TOGGLE_ITEM_IN_WISHLIST';
export const ACTION_ADD_TO_WISHLIST = 'ACTION_ADD_TO_WISHLIST';
export const ACTION_REMOVE_FROM_WISHLIST = 'ACTION_REMOVE_FROM_WISHLIST';

export const DEFAULT_STATE = {
    [STATE_LOADING]: false,
    [STATE_WISHLIST]: {
        hash: null,
        items: []
    }
};
