import { getOrders, getOrdersPagination } from '@/api/orders';
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

import {
    STATE_LOADING,
    STATE_ORDERS,
    STATE_PAGINATION,
    STATE_LAST_ORDER,
    GETTER_LOADING,
    GETTER_ORDERS,
    GETTER_ORDERS_PAGINATION,
    GETTER_LAST_ORDER,
    MUTATION_UPDATE_LOADING,
    MUTATION_UPDATE_ORDERS,
    MUTATION_UPDATE_ORDERS_PAGINATION,
    MUTATION_UPDATE_LAST_ORDER,
    ACTION_INIT_ORDERS,
    ACTION_LOAD_ORDERS,
    ACTION_LOAD_PAGINATION,
    DEFAULT_STATE,
} from "@/store/dashboard/shopify_orders/constants";

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_LOADING](state) {
            return state[STATE_LOADING];
        },
        [GETTER_ORDERS](state) {
            return (state[STATE_ORDERS] || []).reduce((all_orders, order) => {
                const customer_url = order?.customer_url;
                if (!customer_url) {
                    return all_orders;
                }

                const parts = customer_url?.split('/');
                if (!parts) {
                    return all_orders;
                }

                if (!parts?.length) {
                    return all_orders;
                }

                const hash = parts[parts.length - 1];
                if (!hash) {
                    return all_orders;
                }

                all_orders[hash] = order;

                return all_orders;
            }, {});
        },
        [GETTER_ORDERS_PAGINATION](state) {
            return state[STATE_PAGINATION];
        },
        [GETTER_LAST_ORDER](state) {
            return state[STATE_LAST_ORDER];
        }
    },
    mutations: {
        [MUTATION_UPDATE_LOADING](state, loading) {
            state[STATE_LOADING] = loading;
        },
        [MUTATION_UPDATE_ORDERS](state, orders) {
            state[STATE_ORDERS] = orders;
        },
        [MUTATION_UPDATE_ORDERS_PAGINATION](state, pagination) {
            state[STATE_PAGINATION] = pagination;
        },
        [MUTATION_UPDATE_LAST_ORDER](state, last_order) {
            state[STATE_LAST_ORDER] = last_order;
        }
    },
    actions: {
        async [ACTION_INIT_ORDERS](context) {
            context.commit(MUTATION_UPDATE_LOADING, true);

            try {
                const all_orders = [];
                const { orders } = await getOrders();

                orders?.forEach((order) => {
                    all_orders?.push(order);
                });

                context.commit(MUTATION_UPDATE_LAST_ORDER, all_orders?.length > 0 ? all_orders[0] : null);
                context.commit(MUTATION_UPDATE_ORDERS, all_orders);
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error init all orders`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                        });
                    });
                } catch (error) {}
            } finally {
                context.commit(MUTATION_UPDATE_LOADING, false);
            }
        },
        async [ACTION_LOAD_ORDERS](context, {
            page = 1
        }) {
            context.commit(MUTATION_UPDATE_LOADING, true);

            try {
                const all_orders = [];

                const { orders } = await getOrders({
                    page,
                });

                orders?.forEach((order) => {
                    all_orders?.push(order);
                });

                context.commit(MUTATION_UPDATE_ORDERS, all_orders);
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Load all orders error`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}
            } finally {
                context.commit(MUTATION_UPDATE_LOADING, false);
            }
        },
        async [ACTION_LOAD_PAGINATION](context) {
            context.commit(MUTATION_UPDATE_LOADING, true);

            try {
                const pagination = await getOrdersPagination();

                context.commit(MUTATION_UPDATE_ORDERS_PAGINATION, pagination);
            }
            catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Load orders pagination error`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}
            }
            finally {
                context.commit(MUTATION_UPDATE_LOADING, false);
            }
        }
    }
};
