import {
    STATE_LOADING,
    STATE_SETTINGS,
    STATE_CUSTOMIZER,
    STATE_SHOP,
    STATE_OBJECTS,
    GETTER_LOADING,
    GETTER_SETTINGS,
    GETTER_CUSTOMIZER,
    GETTER_SHOP,
    GETTER_OBJECTS,
    MUTATION_UPDATE_LOADING,
    MUTATION_UPDATE_SETTINGS,
    MUTATION_UPDATE_CUSTOMIZER,
    MUTATION_UPDATE_SHOP,
    MUTATION_UPDATE_OBJECTS,
    ACTION_UPDATE_LOADING,
    ACTION_UPDATE_SETTINGS,
    ACTION_UPDATE_CUSTOMIZER,
    ACTION_UPDATE_SHOP,
    ACTION_UPDATE_OBJECTS,
    ACTION_UPDATE_TRANSLATIONS,
    DEFAULT_GLOBALS,
    MUTATION_UPDATE_PAGE_UNLOADED,
    STATE_PAGE_UNLOADED,
    ACTION_UPDATE_PAGE_UNLOADED,
    GETTER_PAGE_UNLOADED,
} from '@/store/minicart/shopify/global/constants';

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_GLOBALS
        };
    },
    getters: {
        [GETTER_LOADING](state) {
            return state[STATE_LOADING];
        },
        [GETTER_SETTINGS](state) {
            return state[STATE_SETTINGS];
        },
        [GETTER_SHOP](state) {
            return state[STATE_SHOP];
        },
        [GETTER_CUSTOMIZER](state) {
            return state[STATE_CUSTOMIZER];
        },
        [GETTER_OBJECTS](state) {
            return state[STATE_OBJECTS];
        },
        [GETTER_PAGE_UNLOADED](state) {
            return state[STATE_PAGE_UNLOADED] || false;
        }
    },
    mutations: {
        [MUTATION_UPDATE_LOADING](state, loading) {
            state[STATE_LOADING] = loading;
        },
        [MUTATION_UPDATE_SETTINGS](state, settings) {
            state[STATE_SETTINGS] = settings;
        },
        [MUTATION_UPDATE_CUSTOMIZER](state, customizer) {
            console.log('customizer', customizer);

            state[STATE_CUSTOMIZER] = customizer;
        },
        [MUTATION_UPDATE_SHOP](state, shop) {
            state[STATE_SHOP] = shop;
        },
        [MUTATION_UPDATE_OBJECTS](state, objects) {
            state[STATE_OBJECTS] = objects;
        },
        [MUTATION_UPDATE_PAGE_UNLOADED](state, page_unloaded) {
            state[STATE_PAGE_UNLOADED] = page_unloaded;
        }
    },
    actions: {
        [ACTION_UPDATE_LOADING](context, { settings = {} }) {
            context.commit(MUTATION_UPDATE_LOADING, settings);
        },
        [ACTION_UPDATE_SETTINGS](context, { settings = {} }) {
            context.commit(MUTATION_UPDATE_SETTINGS, settings);
        },
        [ACTION_UPDATE_CUSTOMIZER](context, { customizer = {} }) {
            context.commit(MUTATION_UPDATE_CUSTOMIZER, customizer);
        },
        [ACTION_UPDATE_SHOP](context, { shop }) {
            context.commit(MUTATION_UPDATE_SHOP, shop);
        },
        [ACTION_UPDATE_OBJECTS](context, { objects }) {
            context.commit(MUTATION_UPDATE_OBJECTS, objects);
        },
        [ACTION_UPDATE_TRANSLATIONS](context, { translations }) {
            context.commit(MUTATION_UPDATE_OBJECTS, translations);
        },
        [ACTION_UPDATE_PAGE_UNLOADED](context, { page_unloaded = false }) {
            context.commit(MUTATION_UPDATE_PAGE_UNLOADED, page_unloaded);
        }
    }
};
