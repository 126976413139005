import {
    ACTION_REMOVE_ITEMS,
    MODULE_NAME as MODULE_CART, GETTER_CART_WITH_PRODUCTS
} from '@/store/minicart/shopify/cart/constants';

import { getProductByHandle } from "@/api/storefront";

import {
    STATE_ROUTE_PROTECTION_PRODUCT,
    GETTER_ROUTE_PROTECTION_PRODUCT,
    GETTER_ROUTE_PROTECTION_ITEMS,
    GETTER_ROUTE_PROTECTION_ITEM_KEYS,
    GETTER_REMOVE_ROUTE_PRODUCTS,
    MUTATION_UPDATE_ROUTE_PROTECTION_PRODUCT,
    ACTION_GET_ROUTE_PROTECTION_PRODUCT,
    ACTION_UPDATE_ROUTE_PROTECTION_PRODUCT,
    ACTION_CHECK_ROUTE_PROTECTION_IN_CART,
    ACTION_REMOVE_ROUTE_PROTECTION,
    DEFAULT_STATE,
} from "@/store/minicart/route-protection/constants";
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_ROUTE_PROTECTION_PRODUCT](state) {
            return state[STATE_ROUTE_PROTECTION_PRODUCT];
        },
        [GETTER_ROUTE_PROTECTION_ITEMS](
            store,
            getters,
            rootState,
            rootGetters
        ) {
            const route_protection_product =
                getters[GETTER_ROUTE_PROTECTION_PRODUCT];
            if (!route_protection_product) {
                return [];
            }

            const cart = rootGetters[`${MODULE_CART}/${GETTER_CART_WITH_PRODUCTS}`];
            if (!cart) {
                return [];
            }

            const { items } = cart;
            if (!items) {
                return [];
            }

            if (!items.length) {
                return [];
            }

            return items.filter((item) => {
                const { product } = item;
                if (!product) {
                    return false;
                }

                return route_protection_product.id === product.id;
            });
        },
        [GETTER_ROUTE_PROTECTION_ITEM_KEYS](state, getters) {
            const items = getters[GETTER_ROUTE_PROTECTION_ITEMS];

            if (!items) {
                return [];
            }

            if (!items.length) {
                return [];
            }

            return items
                .map((item) => item.key)
                .filter((key) => Boolean(key));
        },
        [GETTER_REMOVE_ROUTE_PRODUCTS](state, getters, rootState, rootGetters) {
            const cart = rootGetters[`${MODULE_CART}/${GETTER_CART_WITH_PRODUCTS}`];
            const items_in_cart_length = cart?.items?.length;
            if (!items_in_cart_length) {
                return false;
            }

            const route_protection_items_in_cart = getters[GETTER_ROUTE_PROTECTION_ITEMS];
            const route_protection_items_in_cart_length = route_protection_items_in_cart?.length;
            if (!route_protection_items_in_cart_length) {
                return false;
            }

            return items_in_cart_length === route_protection_items_in_cart_length;
        }
    },
    mutations: {
        [MUTATION_UPDATE_ROUTE_PROTECTION_PRODUCT](state, product) {
            state[STATE_ROUTE_PROTECTION_PRODUCT] = product;
        }
    },
    actions: {
        async [ACTION_GET_ROUTE_PROTECTION_PRODUCT](context) {
            const handle = document?.body?.dataset?.routeProtectionHandle;
            if (!handle) {
                return;
            }

            try {
                const product = await getProductByHandle(handle, {}, {
                    availableForSale: false,
                    createdAt: false,
                    description: false,
                    descriptionHtml: false,
                    featuredImage: false,
                    handle: false,
                    isGiftCard: false,
                    onlineStoreUrl: false,
                    options: false,
                    productType: false,
                    publishedAt: false,
                    requiresSellingPlan: false,
                    sellingPlanGroups: false,
                    seo: false,
                    tags: false,
                    title: false,
                    totalInventory: false,
                    updatedAt: false,
                    vendor: false,
                    images: false,
                    media: false,
                    variants: false,
                    metafields: false,
                });

                if (!product) {
                    return;
                }

                await context.dispatch(ACTION_UPDATE_ROUTE_PROTECTION_PRODUCT, {
                    product,
                });
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Unable to fetch route protection product`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            response: error?.data || error?.response,
                            error,
                        });
                    });
                } catch (error) {}
            }
        },
        [ACTION_UPDATE_ROUTE_PROTECTION_PRODUCT](context, { product = null } = {}) {
            context.commit(MUTATION_UPDATE_ROUTE_PROTECTION_PRODUCT, product);
        },
        async [ACTION_REMOVE_ROUTE_PROTECTION](context) {
            const keys = context.getters[GETTER_ROUTE_PROTECTION_ITEM_KEYS] || [];
            if (!keys?.length) {
                return;
            }

            await context.dispatch(
                `${MODULE_CART}/${ACTION_REMOVE_ITEMS}`,
                {
                    keys
                },
                {
                    root: true
                }
            );
        },
        async [ACTION_CHECK_ROUTE_PROTECTION_IN_CART](context) {
            const removeRouteProtection = context.getters[GETTER_REMOVE_ROUTE_PRODUCTS];
            if (!removeRouteProtection) {
                return;
            }

            await context.dispatch(ACTION_REMOVE_ROUTE_PROTECTION);
        }
    }
};
