import ls from 'localstorage-slim';

import { loadCountries } from '@shopify/theme-addresses/loader';

import {
    STATE_LOADING,
    STATE_COUNTRIES,
    MUTATION_SET_LOADING,
    MUTATION_SET_COUNTRIES,
    GETTER_LOADING,
    GETTER_COUNTRIES,
    ACTION_LOAD_COUNTRIES,
    DEFAULT_STATE,
} from "@/store/dashboard/counties/constants";
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_LOADING](state) {
            return state[GETTER_LOADING];
        },
        [GETTER_COUNTRIES](state) {
            return state[STATE_COUNTRIES];
        }
    },
    mutations: {
        [MUTATION_SET_LOADING](state, loading) {
            state[STATE_LOADING] = loading;
        },
        [MUTATION_SET_COUNTRIES](state, countries) {
            state[STATE_COUNTRIES] = countries;
        }
    },
    actions: {
        async [ACTION_LOAD_COUNTRIES](context) {
            context.commit(MUTATION_SET_LOADING, false);

            try {
                let countries = ls?.get('countries');
                if (!countries) {
                    countries = await loadCountries('en');

                    try {
                        ls?.set('countries', countries, {
                            ttl: 3600,
                        });
                    } catch (error) {}
                }

                context.commit(MUTATION_SET_COUNTRIES, countries);
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error loading countries`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}
            } finally {
                context.commit(MUTATION_SET_LOADING, false);
            }
        }
    }
};
