export default class NetworkOfflineError extends Error {
    request = null;

    constructor(message, request) {
        super(message);

        this.name       = "NetworkOfflineError";
        this.request    = request;
    }
};
