import {
    STATE_ROUTE_CHANGING,
    GETTER_ROUTE_CHANGING,
    MUTATION_UPDATE_ROUTE_CHANGING,
    ACTION_UPDATE_ROUTE_CHANGING,
    DEFAULT_STATE,
} from "@/store/dashboard/constants";

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_ROUTE_CHANGING](state) {
            return state[STATE_ROUTE_CHANGING];
        }
    },
    mutations: {
        [MUTATION_UPDATE_ROUTE_CHANGING](state, changing) {
            state[STATE_ROUTE_CHANGING] = changing;
        }
    },
    actions: {
        [ACTION_UPDATE_ROUTE_CHANGING](context, { changing = false }) {
            context.commit(MUTATION_UPDATE_ROUTE_CHANGING, changing);
        }
    }
};
