export default function (product) {
    if (!product) {
        return null;
    }

    const { selling_plan_groups } = product;
    if (!selling_plan_groups) {
        return null;
    }

    if (!selling_plan_groups.length) {
        return null;
    }

    const first_selling_plan_group = selling_plan_groups[0];
    if (!first_selling_plan_group) {
        return null;
    }

    const { selling_plans } = first_selling_plan_group;
    if (!selling_plans) {
        return null;
    }

    if (!selling_plans?.length) {
        return null;
    }

    const first_selling_plan = selling_plans[0];
    if (!first_selling_plan) {
        return null;
    }

    const { id } = first_selling_plan;
    if (!id) {
        return null;
    }

    return id;
}
