export const MODULE_NAME = 'dashboard';
export const STATE_ROUTE_CHANGING = 'STATE_ROUTE_CHANGING';

export const GETTER_ROUTE_CHANGING = 'GETTER_ROUTE_CHANGING';

export const MUTATION_UPDATE_ROUTE_CHANGING = 'MUTATION_UPDATE_ROUTE_CHANGING';

export const ACTION_UPDATE_ROUTE_CHANGING = 'ACTION_UPDATE_ROUTE_CHANGING';

export const DEFAULT_STATE = {
    [STATE_ROUTE_CHANGING]: false
};
