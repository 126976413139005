export const MODULE_NAME = 'styles';

export const STATE_LOADING = 'loading';
export const STATE_STYLES = 'styles';

export const MUTATION_SET_LOADING = 'MUTATION_SET_LOADING';
export const MUTATION_SET_STYLES = 'MUTATION_SET_STYLES';

export const GETTER_LOADING = 'GETTER_LOADING';
export const GETTER_STYLES = 'GETTER_STYLES';

export const ACTION_LOAD_STYLES = 'ACTION_LOAD_STYLES';

export const DEFAULT_STATE = {
    [STATE_LOADING]: false,
    [STATE_STYLES]: []
};
