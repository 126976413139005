import FetchTimeoutError from '@packages/xhook/errors/fetch-timeout-error';
import NetworkOfflineError from '@packages/xhook/errors/network-offline-error';
import MaxRetriesReachedError from '@packages/xhook/errors/max-retries-reached-error';

const MAX_RETRIES_ATTEMPTS  = 5;
const MAX_FETCH_WAIT        = 10000;

const xhook = {
    beforeCallbacks: [],
    afterCallbacks: [],
    logCallbacks: [],

    before(handler) {
        this.beforeCallbacks.push(handler);
    },

    after(handler) {
        this.afterCallbacks.push(handler);
    },

    log(handler) {
        this.logCallbacks.push(handler);
    }
};

const originalFetch = ((fetch) => {
    return fetch;
})(window.fetch);

async function checkNetworkStatus() {
    if (!!window?.navigator?.onLine) {
        return true;
    }

    const url = new URL(window?.location?.origin || '/');

    url?.searchParams?.set('rand', Date.now().toString());

    try {
        const response = await originalFetch(url.toString(), {
            method: 'HEAD',
            mode: 'no-cors'
        }).catch(() => {
            return false;
        });

        if (!response) {
            return false;
        }

        return !!response?.ok;
    } catch (error) {}

    return false;
}

const retryCalls = async (callback = async () => null, ...params) => {
    let retryCount = 0;

    let exception = null;

    while (retryCount < MAX_RETRIES_ATTEMPTS) {
        try {
            return await callback(...params);
        } catch (error) {
            retryCount++;

            exception = error;

            await new Promise((resolve) => {
                setTimeout(resolve, 1000 * retryCount);
            });
        }
    }

    if ([
        "FetchTimeoutError",
        "NetworkOfflineError",
        "FetchFailedDuringPageUnloadError",
    ]?.includes(exception.name)) {
        throw exception;
    }

    throw new MaxRetriesReachedError("Max Retries Reached", exception);
};

const raceFetch = async (...args) => {
    const isOnline = await checkNetworkStatus();
    if (!isOnline) {
        throw new NetworkOfflineError("Network Offline");
    }

    return new Promise((resolve) => {
        Promise.race([
            originalFetch(...args).then((response) => {
                resolve(response);
            }),
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject(new FetchTimeoutError('Timeout'));
                }, MAX_FETCH_WAIT);
            })
        ]);
    });
};

window.fetch = (() => {
    const {
        beforeCallbacks = [],
        afterCallbacks = [],
        logCallbacks = [],
    } = xhook;

    return async function wrappedFetch(...args) {
        const [url, config = {}] = args;

        const request = {
            ...config,
            url,
            parsedUrl: new URL(url, window?.location?.origin),
        };

        try {
            // eslint-disable-next-line no-restricted-syntax
            for (const beforeCallback of beforeCallbacks) {
                if (typeof beforeCallback !== "function") {
                    continue;
                }

                try {
                    const beforeResponse = await beforeCallback(request);
                    if (typeof beforeResponse === "undefined") {
                        continue;
                    }

                    if (!(beforeResponse instanceof Response)) {
                        continue;
                    }

                    return beforeResponse;
                } catch (error) {}
            }

            const response = await retryCalls(raceFetch, ...args);

            // eslint-disable-next-line no-restricted-syntax
            for (const afterCallback of afterCallbacks) {
                if (typeof afterCallback !== "function") {
                    continue;
                }

                try {
                    const afterResponse = await afterCallback(request, response?.clone() || response);

                    if (typeof afterResponse === "undefined") {
                        continue;
                    }

                    if (!(afterResponse instanceof Response)) {
                        continue;
                    }

                    return afterResponse;
                } catch (error) {}
            }

            return response;
        } catch (error) {
            // eslint-disable-next-line no-restricted-syntax
            for (const logCallback of logCallbacks) {
                if (typeof logCallback !== "function") {
                    continue;
                }

                await logCallback(error);
            }

            if (error.name === "MaxRetriesReachedError") {
                throw error.exception;
            }

            throw error;
        }
    };
})(originalFetch);

export default xhook;
