import axios from 'axios';
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

const merchantId = window?.Shopify?.shop?.replace(".myshopify.com", "");

const client = axios.create({
    baseURL: "https://1y33fhui13.execute-api.us-east-2.amazonaws.com",
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/json'
    },
    params: {
        merchantId
    },
});

export function joinFreeMembership(customerId) {
    try {
        return client.post(`/prod/signup-free-tier`, {
            merchantId,
            customerId
        });
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to join free membership error`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `/prod/credits`,
                    method: "POST",
                    params: {
                        customerId,
                        type: "recent"
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
}

export function getCredits(customerId) {
    try {
        return client.get(`/prod/credits`, {
            params: {
                customerId,
                type: "recent"
            }
        });
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to get Inveterate credits error`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `/prod/credits`,
                    method: "POST",
                    params: {
                        customerId,
                        type: "recent"
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
}

export function redemptionDiscountCode({ customerId, email = null, amount } = {}) {
    try {
        return client.post(`/prod/redemption-discount-code`, {
            customerId,
            email,
            amount
        });
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to get redemption code error`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: `/prod/redemption-discount-code`,
                    method: "POST",
                    params: {
                        customerId,
                        email,
                        amount
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
}

export default {
    client,
    joinFreeMembership,
    getCredits,
    redemptionDiscountCode,
};
