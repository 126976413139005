import {
    ITEM_PROPERTY_ADD_TO_CART_AT,
} from '@/store/minicart/shopify/cart/constants';

import getItemSellingPlan from '@/helpers/get-item-selling-plan';
import {
    ITEM_PROPERTY_BOX_GROUP,
    ITEM_PROPERTY_BOX_HANDLE,
    ITEM_PROPERTY_BOX_IMAGE,
    ITEM_PROPERTY_BOX_PURCHASE_OPTION,
    ITEM_PROPERTY_BOX_SELLING_PLAN,
    ITEM_PROPERTY_BOX_TITLE,
    ITEM_PROPERTY_DISCOUNT_VALUE,
    ITEM_PROPERTY_IS_LASH,
    ITEM_PROPERTY_FREQUENCY_INTERVAL,
    ITEM_PROPERTY_FREQUENCY_UNIT,
    ITEM_PROPERTY_PRICING_STRATEGY,
    ITEM_PROPERTY_IS_RETAIL_PRODUCT,
    ITEM_PROPERTY_MEMBER_DISCOUNT_APPLIED,
    ITEM_PROPERTY_MEMBER_DISCOUNT_VALUE,
} from "@/store/minicart/boxes/constants";

export default function (item) {
    const properties = item?.properties || {};
    const default_hash_parts = {
        variant_id: item?.variant_id?.toString() || item?.id?.toString(),
        selling_plan: getItemSellingPlan(item)
    };

    const hash = Object?.keys(properties)?.filter((key) => {
        return ![
            ITEM_PROPERTY_ADD_TO_CART_AT,
            ITEM_PROPERTY_DISCOUNT_VALUE,
            ITEM_PROPERTY_IS_LASH,
            ITEM_PROPERTY_BOX_GROUP,
            ITEM_PROPERTY_BOX_HANDLE,
            ITEM_PROPERTY_BOX_TITLE,
            ITEM_PROPERTY_BOX_IMAGE,
            ITEM_PROPERTY_BOX_SELLING_PLAN,
            ITEM_PROPERTY_FREQUENCY_INTERVAL,
            ITEM_PROPERTY_FREQUENCY_UNIT,
            ITEM_PROPERTY_PRICING_STRATEGY,
            ITEM_PROPERTY_BOX_PURCHASE_OPTION,
            ITEM_PROPERTY_IS_RETAIL_PRODUCT,
            ITEM_PROPERTY_MEMBER_DISCOUNT_APPLIED,
            ITEM_PROPERTY_MEMBER_DISCOUNT_VALUE,
        ]?.includes(key);
    })?.reduce((merged_properties, key) => {
        return {
            ...merged_properties,
            [key]: properties[key]
        };
    }, Object?.keys(default_hash_parts)?.filter((key) => {
        return !!default_hash_parts[key];
    })?.reduce((hash_parts, key) => ({
        ...hash_parts,
        [key]: default_hash_parts[key]
    }), {}) || {}) || {};

    return JSON.stringify(hash);
}
